var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useCallback, useState } from "react";
import { SubMenuStyled, SubMenuImagesBlock, SubMenuImage, SubMenuTitle, SubMenuTitleName, SubMeniDropdow, SubMenuArrow, SubMenuBody, SubMenuImagesUrl, SubMeniDropdowImg, } from "./styled";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { IMAGE_BALANCER_ROOM_TYPE } from "src/constants";
export var SubMenuItems = memo(function SubMenuItems(_a) {
    var menuItem = _a.menuItem, onClose = _a.onClose, handleAddFurnitureOnRoom = _a.handleAddFurnitureOnRoom, typeName = _a.typeName;
    var _b = __read(useState([]), 2), openedType = _b[0], setOpenedType = _b[1];
    var _c = __read(useState(""), 2), selectedImage = _c[0], setSelectedImage = _c[1];
    var handleSelectImage = useCallback(function (name) {
        setSelectedImage(name);
    }, []);
    var handleUpdateOpenedType = useCallback(function (type) { return function () {
        setOpenedType(function (selectedTypes) {
            if (selectedTypes.includes(type)) {
                return selectedTypes.filter(function (selected) { return selected !== type; });
            }
            return __spreadArray(__spreadArray([], __read(selectedTypes), false), [type], false);
        });
    }; }, []);
    var isDisplay = useCallback(function (type) { return openedType.includes(type); }, [openedType]);
    var handleOnClickImage = useCallback(function (image) {
        setOpenedType([]);
        handleAddFurnitureOnRoom({ furnitureType: image });
        handleSelectImage(image.name);
    }, [handleAddFurnitureOnRoom, handleSelectImage]);
    return (React.createElement(SubMenuStyled, null,
        React.createElement(SubMenuTitle, null,
            React.createElement(CommonText, { text: typeName }),
            React.createElement(CommonButton, { onClick: onClose },
                React.createElement(CommonText, { color: "green", text: "\u041D\u0430\u0437\u0430\u0434" }))),
        React.createElement("div", null,
            React.createElement(SubMenuTitleName, null,
                React.createElement(CommonButton, { onClick: handleUpdateOpenedType(menuItem.category_type) },
                    React.createElement(SubMeniDropdow, null,
                        React.createElement(SubMeniDropdowImg, { src: "".concat(IMAGE_BALANCER_ROOM_TYPE).concat(menuItem.catalog_image_src), alt: menuItem.name_rus }),
                        React.createElement(CommonText, { text: menuItem.name_rus }),
                        React.createElement(SubMenuArrow, null,
                            React.createElement("img", { src: "/img/Arrow 1.png", alt: "arrow" }))))),
            isDisplay(menuItem.category_type) && (React.createElement(SubMenuBody, null,
                React.createElement(SubMenuImagesBlock, null, menuItem.furnitures.map(function (image) {
                    return (React.createElement(SubMenuImage, { key: image.id, "$isActive": selectedImage === image.name_rus },
                        React.createElement(CommonButton, { onClick: function () { return handleOnClickImage(image); } },
                            React.createElement(SubMenuImagesUrl, { src: "".concat(IMAGE_BALANCER_ROOM_TYPE).concat(image.icon_src) })),
                        React.createElement(CommonText, { text: image.name_rus, fontSize: "9px" })));
                })))))));
});
