var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState, useCallback } from "react";
import { getProjectsRequest } from "src/api/requests/get-projects";
import { MainMenu } from "../mainMenu";
import { LoadInfo } from "../load-info";
import { TopMenu } from "../topMenu";
import { postRoomsRequest } from "src/api/requests/post-rooms";
import { Project } from "src/class/export";
import { postGenerateAutoRequest } from "src/api/requests/generage-auto";
import { MainHeaderIcon } from "../mainMenu/_components/mainHeaderIcon";
import { getCollageRequest } from "src/api/requests/get-collage";
import { generateFurniture } from "src/utils/create-catalog-element/generate-furniture";
import { generateDoor } from "src/utils/create-catalog-element/generate-door";
import { generateWindow } from "src/utils/create-catalog-element/generate-window";
import { postAddProjectRequest } from "src/api/requests/post-add-project";
import { getUsersRequest } from "src/api/requests/get-users";
import { postAddUserRequest } from "src/api/requests/post-user";
import { getProjectByIdRequest } from "src/api/requests/get-project-by-id";
import { updateProjectByIdRequest } from "src/api/requests/update-project-by-id";
import { getLayoutsRequest } from "src/api/requests/get-layouts";
import { postAddLayoutsRequest } from "src/api/requests/post-layout";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from "src/constants";
import { ModalQuiz } from "../modal-quiz";
export var Meta = memo(function Meta(props) {
    var projectActions = props.projectActions, catalog = props.catalog, isLoadingMeta = props.isLoadingMeta, setIsPrinting = props.setIsPrinting, state = props.state, viewer3DActions = props.viewer3DActions, viewer2DActions = props.viewer2DActions;
    // console.log(props)
    // console.log(props)
    // console.log(props)
    var _a = __read(useState([]), 2), allProjects = _a[0], setAllProjects = _a[1];
    var _b = __read(useState(false), 2), isLoadingProjects = _b[0], setIsLoadingProjects = _b[1];
    var _c = __read(useState(false), 2), isLoadingRooms = _c[0], setIsLoadingRooms = _c[1];
    var _d = __read(useState(false), 2), isLoadingSaveRooms = _d[0], setIsLoadingSaveRooms = _d[1];
    var _e = __read(useState(false), 2), isLoadingCollage = _e[0], setIsLoadingCollage = _e[1];
    var _f = __read(useState(false), 2), isLoadingFurnitures = _f[0], setIsLoadingFurnitures = _f[1];
    var _g = __read(useState(false), 2), isLoadingRoomTypes = _g[0], setIsLoadingRoomTypes = _g[1];
    var _h = __read(useState(false), 2), isLoadingShards = _h[0], setIsLoadingShards = _h[1];
    var _j = __read(useState([]), 2), windows = _j[0], setWindows = _j[1];
    var _k = __read(useState([]), 2), doors = _k[0], setDoors = _k[1];
    var _l = __read(useState([]), 2), furnitures = _l[0], setFurnitures = _l[1];
    var _m = __read(useState([]), 2), roomTypes = _m[0], setRoomTypes = _m[1];
    var _o = __read(useState(false), 2), isOpenQuiz = _o[0], setIsOpenQuiz = _o[1];
    // console.log(catalog)
    var _p = __read(useState(false), 2), isLoadingAdminAction = _p[0], setIsLoadingAdminAction = _p[1];
    var _q = __read(useState([]), 2), allLayouts = _q[0], setAllLayouts = _q[1];
    var _r = __read(useState(false), 2), isLoadingLayouts = _r[0], setIsLoadingLayouts = _r[1];
    var _s = __read(useState(false), 2), hardClear = _s[0], setHardClear = _s[1];
    var handleFalseHardClear = function () {
        setHardClear(false);
    };
    var handleCloseQuiz = function () {
        setIsOpenQuiz(false);
    };
    var handleOpenQuiz = function () {
        setIsOpenQuiz(true);
    };
    var handleLoadLayouts = function (layouts) {
        setAllLayouts(layouts);
    };
    var handleStartLoadingLayouts = function () {
        setIsLoadingLayouts(true);
    };
    var handleStopLoadingLayouts = function () {
        setIsLoadingLayouts(false);
    };
    var handleStartLoadingAdminAction = function () {
        // setIsLoadingAdminAction(true)
    };
    var handleStopLoadingAdminAction = function () {
        // setIsLoadingAdminAction(false)
    };
    // FOR_ADMIN
    var _t = __read(useState([]), 2), currentUsers = _t[0], setCureentUsers = _t[1];
    var handleUpdateProjects = function (projects) {
        setAllProjects(projects);
    };
    var handleStartLoadinProjects = useCallback(function () {
        setIsLoadingProjects(true);
    }, []);
    var handleStopLoadingProjects = useCallback(function () {
        setIsLoadingProjects(false);
    }, []);
    var handleStartLoadingRooms = useCallback(function () {
        isLoadingMeta.isStartLoadingMeta();
        setIsLoadingRooms(true);
    }, []);
    var handleStopLoadingRooms = useCallback(function () {
        isLoadingMeta.isStopLoadingMeta();
        setIsLoadingRooms(false);
    }, []);
    var handleStartLoadingSaveRooms = useCallback(function () {
        // setIsLoadingSaveRooms(true);
        isLoadingMeta.isStartLoadingMeta();
    }, []);
    var handleStopLoadingSaveRooms = useCallback(function () {
        // setIsLoadingSaveRooms(false);
        isLoadingMeta.isStopLoadingMeta();
    }, []);
    var handleStartLoadingCollage = useCallback(function () {
        setIsLoadingCollage(true);
    }, []);
    var handleStopLoadingCollage = useCallback(function () {
        setIsLoadingCollage(false);
    }, []);
    var handleLoadCollage = useCallback(function (collageData) {
        props.setCollageInfo(collageData);
    }, []);
    var handleLoadEstimates = useCallback(function () {
        console.log("props");
        console.log(props);
        console.log("props.collageInfo");
        console.log(props.collageInfo);
        if (!props.collageInfo.data) {
            getCollageRequest({
                startLoadingCallback: handleStartLoadingCollage,
                stopLoadingCallback: handleStopLoadingCollage,
                onSuccess: handleLoadCollage,
            });
        }
    }, []);
    var handleLoadRooms = useCallback(function (data) {
        // const handleLoadRoomsOnSuccess = (rooms: Array<TRoom>) => {
        //   const newFormat = transformObject(rooms[0]);
        //   projectActions.loadProject(newFormat);
        // };
        // const newFormat = transformObject(data);
        // console.log(data)
        // console.log('before')
        projectActions.loadProject(data);
        // console.log('after')
        // console.log(data)
        // getRoomsRequest({
        //   startLoadingCallback: handleStartLoadingRooms,
        //   stopLoadingCallback: handleStopLoadingRooms,
        //   onSuccess: handleLoadRoomsOnSuccess,
        //   // onError: commonCallbackError,
        // });
    }, [handleStartLoadingRooms, handleStopLoadingRooms]);
    var handleRefreshLayouts = function () {
        // handleLoadRooms();
        localStorageWorker.remove({
            key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_ID,
        });
        localStorageWorker.remove({
            key: LOCAL_STRORAGE_KEYS.SELECTED_LAYOUT_NAME,
        });
        setHardClear(true);
        getLayoutsRequest({
            startLoadingCallback: handleStartLoadingLayouts,
            stopLoadingCallback: handleStopLoadingLayouts,
            onSuccess: handleLoadLayouts,
        });
        handleLoadRooms({});
    };
    var handleRefresh = function (data) {
        handleLoadRooms(data);
        // getLayoutsRequest({
        //   startLoadingCallback:handleStartLoadingLayouts,
        //   stopLoadingCallback:handleStopLoadingLayouts,
        //   onSuccess: handleLoadLayouts,
        // })
    };
    var handleSelectLayout = function (data) {
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
            value: data.layout_area_temp,
        });
        var metaLayer = {
            unit: "cm",
            layers: {
                "layer-1": data,
            },
            grids: {
                h1: {
                    id: "h1",
                    type: "horizontal-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
                v1: {
                    id: "v1",
                    type: "vertical-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"],
                    },
                },
            },
            selectedLayer: "layer-1",
            groups: {},
            width: 3000,
            height: 2000,
            meta: {},
            guides: {
                horizontal: {},
                vertical: {},
                circular: {},
            },
        };
        var layer = data.id
            ? metaLayer
            : { layout_area_temp: data.layout_area_temp };
        handleLoadRooms(layer);
        // console.log(data)
    };
    var handleBackActionButton = useCallback(function () { }, []);
    var handleNextActionButton = useCallback(function () { }, []);
    var handleMinusZoomScene = useCallback(function () { }, []);
    var handlePlusZoomScene = useCallback(function () { }, []);
    var handleSaveRooms = useCallback(function () {
        var state = Project.unselectAll(props.state).updatedState;
        var stateScene = state.get("scene").toJS();
        var getlayoutAreaTemp = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
        });
        var layer = stateScene.layers["layer-1"];
        Object.values(layer.items).forEach(function (item) {
            var element = catalog.elements[item.type];
            if (element) {
                layer.items[item.id].catalog = element === null || element === void 0 ? void 0 : element.catalog;
            }
        });
        var data = __assign(__assign({}, layer), { layout_area_temp: getlayoutAreaTemp });
        postRoomsRequest({
            startLoadingCallback: handleStartLoadingSaveRooms,
            stopLoadingCallback: handleStopLoadingSaveRooms,
            data: data,
        });
    }, [props, handleStartLoadingSaveRooms, handleStopLoadingSaveRooms]);
    var handleOpenEvaluates = useCallback(function () {
        props.setIsOpenEvaluates(true);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenScene(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleOpenScene = useCallback(function () {
        props.setIsOpenScene(true);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleOpenCollage = useCallback(function () {
        props.setIsOpenScene(false);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollageAndEstimates(false);
        props.setIsOpenCollage(true);
    }, []);
    var handleOpenCollageAndEstimates = useCallback(function () {
        props.setIsOpenCollageAndEstimates(true);
        props.setIsOpenScene(false);
        props.setIsOpenEvaluates(false);
        props.setIsOpenCollage(false);
    }, []);
    var handleStartLoadingFurnitures = useCallback(function () {
        setIsLoadingFurnitures(true);
    }, []);
    var handleStopLoadingFurnitures = useCallback(function () {
        setIsLoadingFurnitures(false);
    }, []);
    var handleStartLoadingRoomTypes = useCallback(function () {
        setIsLoadingRoomTypes(true);
    }, []);
    var handleStopLoadingRoomTypes = useCallback(function () {
        setIsLoadingRoomTypes(false);
    }, []);
    var handleStartLoadingShards = useCallback(function () {
        setIsLoadingShards(true);
    }, []);
    var handleStopLoadingShards = useCallback(function () {
        setIsLoadingShards(false);
    }, []);
    var handleLoadWindows = function (widnows) {
        var holes = generateWindow({ catalog: catalog, window: widnows });
        // console.log(holes)
        catalog.registerCategory("windows", "Windows", holes);
        // catalog.registerCategory('doors', 'Doors', [Holes.door, Holes.doorDouble, Holes.panicDoor, Holes.panicDoorDouble, Holes.slidingDoor] );
        // setWindows(widnows);
    };
    var handleLoadDoors = function (doors) {
        var holes = generateDoor({ door: doors, catalog: catalog });
        catalog.registerCategory("doors", "Doors", holes);
    };
    var handleLoadRoomTypes = function (roomTypes) {
        // console.log(roomTypes);
        setRoomTypes(roomTypes);
    };
    var handleLoadFurnitures = function (furniture) {
        // console.log(furniture)
        generateFurniture({ catalog: catalog, furniture: furniture });
        // console.log(furniture)
        // createCatalogElement(catalog)e
        // generateFurniture({
        //   catalog,
        //   furniture
        // })
        // setFurnitures(furniture);
    };
    // console.log('nije')
    // console.log(Catalog)
    var handleUpdateCatalog = function () {
        projectActions.initCatalog(catalog);
        // console.log(projectActions)
        isLoadingMeta.isStopLoadingMeta();
    };
    var handleGenerateAuto = function () {
        // const state = Project.unselectAll(props.state).updatedState;
        // const stateScene = state.get("scene").toJS();
        // const oldFormat = reverseTransform(stateScene.layers["layer-1"]);
        // const olddFormatScaled = getReversScaledRooms([oldFormat]);
        var state = Project.unselectAll(props.state).updatedState;
        var stateScene = state.get("scene").toJS();
        // const oldFormat = reverseTransform(stateScene.layers["layer-1"]);
        // const olddFormatScaled = getReversScaledRooms([oldFormat]);
        var getlayoutAreaTemp = localStorageWorker.get({
            key: LOCAL_STRORAGE_KEYS.SELECTED_AREA_TEMP,
        });
        var layer = stateScene.layers["layer-1"];
        var data = __assign(__assign({}, layer), { layout_area_temp: getlayoutAreaTemp });
        var generateAfterSuccessSave = function () {
            postGenerateAutoRequest({
                // startLoadingCallback: handleStartLoadingSaveRooms,
                stopLoadingCallback: handleStopLoadingSaveRooms,
                onSuccess: handleRefreshLayouts,
                // data: olddFormatScaled,
                //
            });
        };
        postRoomsRequest({
            startLoadingCallback: handleStartLoadingSaveRooms,
            // stopLoadingCallback: handleStopLoadingSaveRooms,
            data: data,
            onSuccess: generateAfterSuccessSave,
        });
    };
    var handleGetAllProjects = function () {
        getProjectsRequest({
            startLoadingCallback: handleStartLoadinProjects,
            stopLoadingCallback: handleStopLoadingProjects,
            onSuccess: handleUpdateProjects,
            // onError: commonCallbackError,
        });
    };
    var handleAddProject = function (_a) {
        var name = _a.name, callbackOnSuccess = _a.callbackOnSuccess;
        var onSuccess = function () {
            callbackOnSuccess();
            handleGetAllProjects();
        };
        postAddProjectRequest({
            data: { name: name },
            onSuccess: onSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleAddLyaouts = function (_a) {
        var name = _a.name, callbackOnSuccess = _a.callbackOnSuccess, layout_area_temp = _a.layout_area_temp;
        var onSuccess = function () {
            callbackOnSuccess();
            handleGetAllProjects();
            getLayoutsRequest({
                startLoadingCallback: handleStartLoadingLayouts,
                stopLoadingCallback: handleStopLoadingLayouts,
                onSuccess: handleLoadLayouts,
            });
        };
        postAddLayoutsRequest({
            data: { name: name, data: { layout_area_temp: layout_area_temp }, layout_area_temp: layout_area_temp },
            onSuccess: onSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleAddUser = function (_a) {
        var name = _a.name, password = _a.password, callbackOnSuccess = _a.callbackOnSuccess;
        var handleOnSuccessAddUser = function () {
            // handleRefresh();
            callbackOnSuccess();
        };
        postAddUserRequest({
            data: {
                name: name,
                password: password,
            },
            onSuccess: handleOnSuccessAddUser,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleGetUsers = function () {
        getUsersRequest({
            onSuccess: function (users) { return setCureentUsers(users); },
        });
    };
    var getProjectById = function (id) {
        var handleCallbackOnSuccess = function (data) {
            var fillteredProject = allProjects.filter(function (project) { return project.id !== id; });
            fillteredProject.push(data);
            handleUpdateProjects(fillteredProject);
        };
        getProjectByIdRequest({
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
            id: id,
            onSuccess: handleCallbackOnSuccess,
        });
    };
    var handleUpdateProjectById = function (_a) {
        var id = _a.id, name = _a.name, callbackOnSuccess = _a.callbackOnSuccess;
        updateProjectByIdRequest({
            data: { name: name },
            id: id,
            onSuccess: callbackOnSuccess,
            startLoadingCallback: handleStartLoadingAdminAction,
            stopLoadingCallback: handleStopLoadingAdminAction,
        });
    };
    var handleLoadRoomsT = function () { };
    var onSelectExample = function (room) { };
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadInfo, { handleUpdateCatalog: handleUpdateCatalog, handleStartLoadingFurnitures: handleStartLoadingFurnitures, handleStopLoadingFurnitures: handleStopLoadingFurnitures, handleStartLoadingRoomTypes: handleStartLoadingRoomTypes, handleStopLoadingRoomTypes: handleStopLoadingRoomTypes, handleStopLoadingShards: handleStopLoadingShards, handleStartLoadingShards: handleStartLoadingShards, handleLoadProjects: handleUpdateProjects, handleStartLoadinProjects: handleStartLoadinProjects, handleStopLoadingProjects: handleStopLoadingProjects, handleLoadFurnitures: handleLoadFurnitures, handleLoadRoomTypes: handleLoadRoomTypes, handleLoadDoors: handleLoadDoors, handleLoadWindows: handleLoadWindows, isLoadingMeta: isLoadingMeta, handleLoadLayouts: handleLoadLayouts, handleStartLoadingLayouts: handleStartLoadingLayouts, handleStopLoadingLayouts: handleStopLoadingLayouts }),
        React.createElement(TopMenu, { handleSaveProject: handleSaveRooms, allProjects: allProjects, 
            // handleRefresh={handleRefresh}
            handleRefreshLayouts: handleRefreshLayouts, handleGenerateProjectFromBuild: handleGenerateAuto, handleAddProject: handleAddProject, handleAddUser: handleAddUser, handleGetUsers: handleGetUsers, currentUsers: currentUsers, handleGetAllProjects: handleGetAllProjects, getProjectById: getProjectById, handleUpdateProjectById: handleUpdateProjectById, isLoadingAdminAction: isLoadingAdminAction, allLayouts: allLayouts, handleAddLyaouts: handleAddLyaouts, handleSelectLayout: handleSelectLayout, hardClear: hardClear, handleFalseHardClear: handleFalseHardClear, roomTypes: roomTypes, handleOpenQuiz: handleOpenQuiz, onSelectExample: onSelectExample, isLoadingRooms: false, isLoadingSaveRooms: false, isLoadingGenerateProject: false, handleLoadRooms: handleLoadRoomsT }),
        React.createElement(MainMenu, { state: state, 
            //  handleBuildModeStart={handleStartBuildBode}
            //  handleBuildModeStop={handleStopBuildBode}
            //  handleInstallDoor={handleAddPortalOnWall}
            handleMinusZoomScene: handleMinusZoomScene, handlePlusZoomScene: handlePlusZoomScene, handleBackActionButton: handleBackActionButton, handleNextActionButton: handleNextActionButton, handleOpenEvaluates: handleOpenEvaluates, handleOpenCollageAndEstimates: handleOpenCollageAndEstimates, 
            //  handlePrint={handlePrint}
            handleOpenCollage: handleOpenCollage, handleOpenScene: handleOpenScene, 
            //  handleChangeWallThickness={handleChangeWallThickness}
            //  handleChangeWallHeight={handleChangeWallHeight}
            //  handleSelectStructure={handleSelectStructure}
            //  handleSelectWindow={handleSelectWindow}
            //  handleSelectDoor={handleSelectDoor}
            //  handleSelectRoomShard={handleSelectRoomShard}
            //  handleChangeStructureHeightLabel={handleChangeStructureHeightLabel}
            //  handleChangeStructureWidthLabel={handleChangeStructureWidthLabel}
            //  handleChangeStructureLengthLabel={handleChangeStructureLengthLabel}
            //  handleChangeWindowHeightFromFloorLabel={
            //    handleChangeWindowHeightFromFloorLabel
            //  }
            //  handleChangeWindowLengthLabel={handleChangeWindowLengthLabel}
            //  handleChangeWindowWidthLabel={handleChangeWindowWidthLabel}
            //  handleChangeDoorLengthLabel={handleChangeDoorLengthLabel}
            //  handleChangeDoorWidthLabel={handleChangeDoorWidthLabel}
            //  handleChangeRoomLengthLabel={handleChangeRoomLengthLabel}
            //  handleChangeRoomWidthLabel={handleChangeRoomWidthLabel}
            //  handleAddFurnitureOnRoom={handleAddFurnitureOnRoom}
            //  onLoadEstimates={onLoadEstimates}
            //  handleIsSquareBuild={handleIsSquareBuild}
            roomTypes: roomTypes, 
            //  isOpenCollageAndEstimates={isOpenCollageAndEstimates}
            //  isOpenCollage={isOpenCollage}
            //  isOpenScene={isOpenScene}
            //  isOpenEvaluates={isOpenEvaluates}
            //  selectedWallThickness={selectedWallThickness}
            furnitures: furnitures, 
            // structureHeightLabel={structureHeightLabel}
            // structureLengthLabel={structureLengthLabel}
            // structureWidthLabel={structureWidthLabel}
            // windowHeightFromFloorLabel={windowHeightFromFloorLabel}
            // windowLengthLabel={windowLengthLabel}
            // windowWidthLabel={windowWidthLabel}
            // doorLengthLabel={doorLengthLabel}
            // doorWidthLabel={doorWidthLabel}
            // wallThickness={wallThickness}
            // wallHeight={wallHeight}
            // roomLenghtLabel={roomLenghtLabel}
            // roomWidthLabel={roomWidthLabel}
            // selectRoomShard={selectRoomShard}
            // selectDoor={selectDoor}
            // selectWindow={selectWindow}
            // selectStructure={selectStructure}
            //  roomImagesShards={shards}
            roomImagesDoors: doors, roomImagesWindow: windows }),
        React.createElement(MainHeaderIcon, { state: state, handleBackActionButton: handleBackActionButton, handleNextActionButton: handleNextActionButton, handleMinusZoomScene: handleMinusZoomScene, handlePlusZoomScene: handlePlusZoomScene, handleOpenEvaluates: handleOpenEvaluates, handleOpenCollageAndEstimates: handleOpenCollageAndEstimates, handlePrint: function () {
                setIsPrinting(true);
            }, handleOpenCollage: handleOpenCollage, handleOpenScene: handleOpenScene, onLoadEstimates: handleLoadEstimates, isOpenCollageAndEstimates: props.isOpenCollageAndEstimates, isOpenCollage: props.isOpenCollage, isOpenScene: props.isOpenScene, isOpenEvaluates: props.isOpenEvaluates, handleGenerateProjectFromBuild: handleGenerateAuto, viewer3DActions: viewer3DActions, viewer2DActions: viewer2DActions, projectActions: projectActions }),
        React.createElement(ModalQuiz, { onCloseQuiz: handleCloseQuiz, isOpenQuiz: isOpenQuiz })));
});
