import React, { memo } from "react";
import { CommonButton } from "src/ui-kit/button";
import { EstimatesStyled, RefreshSvgStyled } from "./styled";
import { RefreshSvg } from "./refreshSvg";
import Collage from "src/components/CollageComponens/Collage";
import { EstimatesTable } from "./estimates-table";
export var Estimates = memo(function Estimates(_a) {
    var estimatesData = _a.estimatesData, isCollage = _a.isCollage, handleSyncCollage = _a.handleSyncCollage, handleEditFurniture = _a.handleEditFurniture, setChangingFurnitureId = _a.setChangingFurnitureId, isLoadingCollage = _a.isLoadingCollage, setIsLoadingCollage = _a.setIsLoadingCollage, setCollageInfo = _a.setCollageInfo, collageInfo = _a.collageInfo;
    console.log("Estimates");
    return (React.createElement(EstimatesStyled, { "$isCollage": isCollage },
        React.createElement(RefreshSvgStyled, null,
            React.createElement(CommonButton, { onClick: handleSyncCollage },
                React.createElement(RefreshSvg, null))),
        isCollage && (React.createElement(Collage, { isFullWindow: false, setChangingFurnitureId: setChangingFurnitureId, isLoadingCollage: isLoadingCollage, setIsLoadingCollage: setIsLoadingCollage, setCollageInfo: setCollageInfo, collageInfo: collageInfo })),
        React.createElement(EstimatesTable, { handleEditFurniture: handleEditFurniture, estimatesData: estimatesData, isCollage: false })));
});
