import React, { useEffect, useRef } from "react";
import { PrintedContainer } from "./styled";
import Collage from "src/components/CollageComponens/Collage";
import { EstimatesTable } from "src/components/estimates/estimates-table";
import { useReactToPrint } from "react-to-print";
export var PrintedCmoponent = function (props //,
// ref: React.ForwardedRef<HTMLDivElement>
) {
    console.log("PrintedCmoponent");
    var estimatesData = props.estimatesData, isPrinting = props.isPrinting, isLoadingCollage = props.isLoadingCollage, setIsLoadingCollage = props.setIsLoadingCollage, setCollageInfo = props.setCollageInfo, collageInfo = props.collageInfo, setIsPrinting = props.setIsPrinting;
    // console.log("estimatesData");
    // console.log(estimatesData);
    var refPrintedComponent = useRef();
    var runPrint = useReactToPrint({
        content: function () { return refPrintedComponent.current; },
        onAfterPrint: function () { return setIsPrinting(false); },
    });
    useEffect(function () {
        if (isPrinting && estimatesData.length > 0) {
            setTimeout(function () {
                console.log("runPrint");
                runPrint();
            }, 1);
        }
    }, [isPrinting, runPrint, estimatesData]);
    return (React.createElement(PrintedContainer, null,
        React.createElement("div", { ref: refPrintedComponent },
            React.createElement(Collage, { isFullWindow: false, isPrint: true, setChangingFurnitureId: function (id) { }, isLoadingCollage: isLoadingCollage, setIsLoadingCollage: setIsLoadingCollage, setCollageInfo: setCollageInfo, collageInfo: collageInfo }),
            React.createElement(EstimatesTable, { estimatesData: estimatesData, isCollage: false }))));
};
