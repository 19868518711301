import { CollageImageComponent } from "./CollageImageComponent.js";
import { CollageComponent } from "./CollageComponent.js";

class CollageLayout extends CollageImageComponent {
  // _objects = [];
  // _interiorBox = null;
  // canvas = this;

  constructor(
    ctx,
    options = {},
    parent = null,
    isPrint = false,
    printedCollageWidth = 0
  ) {
    super(ctx, options, parent, isPrint, printedCollageWidth);

    this._objects = [];
    this._interiorBox = null;
    this.canvas = this;
  }

  // async addObject(options = {}) {
  //   let image = new CollageImageComponent(this._ctx, options, this);
  //   await image.init();
  //   this._objects.push(image);
  // }

  addObject(options = {}) {
    let image = new CollageImageComponent(this._ctx, options, this);
    return image.init().then(() => {
      this._objects.push(image);
      return;
    });
  }

  get objects() {
    return this._objects;
  }

  set interiorBox(interiorBox) {
    this._interiorBox = interiorBox;
  }

  render() {
    if (this._dom) {
      this._dom.updateData();

      let startTop = this.getY();
      let heightImage = this._heightNatural * this._ratioNatural; // * this._ratioWidth;
      let widthImage = this._widthNatural * this._ratioNatural;
      if (this._parent && this._parent._dom)
        this._parent._dom.clientWidth / widthImage;
      const a = this._isPrint
        ? this._printedCollageWidth
        : this._parent && this._parent._dom && this._parent._dom.clientWidth;
      let countItemWidth = Math.ceil(a / widthImage);

      let countItemHeight = Math.ceil(
        (this._ctx && this._ctx.canvas.height) / heightImage
      );
      for (var i = 0; i < countItemHeight; i++) {
        for (var j = 0; j < countItemWidth; j++) {
          this._ctx.drawImage(
            this._dom,
            j * widthImage,
            startTop + i * heightImage,
            widthImage,
            heightImage
          );
        }
      }
    }
    //  setTimeout(() => {
    this.objects.forEach((item) => {
      //   console.log(item);
      item.render();
    });
    //  console.log("render");
    //  }, 1);
  }
}

export { CollageLayout };
