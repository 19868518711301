import { fetchPostRequests } from "src/api/fetch/post";
import { addProjectUrl } from "src/api/urls/post-add-project";
export var postAddProjectRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    // console.log(data)
    fetchPostRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: addProjectUrl,
        data: data,
    });
};
