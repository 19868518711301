import React, { memo } from "react";
export var CreateStart = memo(function CreateSvg() {
    return (React.createElement("svg", { width: "136", height: "138", viewBox: "0 0 136 138", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1554_8597)" },
            React.createElement("path", { d: "M34 66.7383V103.5H70.2289L62.6733 95.8333H41.5556V74.4049L34 66.7383Z", fill: "#8DC646" }),
            React.createElement("path", { d: "M131.731 123.778L120.889 112.623V122.666H15.1113V106.758H22.6668V100.624H15.1113V75.1328H22.6668V68.9995H15.1113V44.4662H22.6668V38.3328H15.1113V16.9045L75.3291 77.6628V66.8528L14.0157 4.94451C13.4873 4.4039 12.8122 4.0352 12.0765 3.88544C11.3408 3.73567 10.5778 3.81161 9.8848 4.10359C9.19179 4.39556 8.60017 4.89033 8.18536 5.52482C7.77056 6.15932 7.55136 6.90478 7.55573 7.66617V126.5C7.55573 127.516 7.95374 128.491 8.66221 129.21C9.37068 129.929 10.3316 130.333 11.3335 130.333H129.049C129.799 130.337 130.534 130.115 131.159 129.694C131.785 129.273 132.272 128.673 132.56 127.97C132.848 127.266 132.923 126.492 132.775 125.746C132.627 124.999 132.264 124.314 131.731 123.778Z", fill: "#8DC646" }),
            React.createElement("path", { d: "M90.6669 115H105.778C107.782 115 109.704 114.192 111.121 112.754C112.538 111.317 113.334 109.367 113.334 107.333V33.3499L104.645 17.1349C103.973 15.9535 103.002 14.9755 101.833 14.3016C100.665 13.6278 99.3399 13.2825 97.9958 13.3015C96.6212 13.3126 95.2757 13.704 94.104 14.4335C92.9324 15.163 91.9792 16.203 91.3469 17.4415L83.1113 33.4265V107.333C83.1113 109.367 83.9074 111.317 85.3243 112.754C86.7412 114.192 88.663 115 90.6669 115ZM90.6669 35.2665L97.9958 20.8149L105.778 35.3049V91.9998H90.6669V35.2665ZM90.6669 98.2482H105.778V107.602H90.6669V98.2482Z", fill: "#8DC646" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1554_8597" },
                React.createElement("rect", { width: "136", height: "138", fill: "white" })))));
});
