export var responseDataFormatter = function (response) {
    // console.log(response);
    // const updateCatalogImageSrc = (items) => {
    //   const formattItems = items;
    //   formattItems.forEach((item) => {
    //     item.catalog_image_src = "/images/furniture_categories/poufs.png";
    //     if (item.furnitures && item.furnitures.length > 0) {
    //       updateCatalogImageSrc(item.furnitures);
    //     }
    //   });
    //   return formattItems;
    // };
    // const onlyForDev = updateCatalogImageSrc(response.data);
    // console.log(response)
    return response.data.data;
    // return response.data
};
