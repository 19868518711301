"use client";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _this = this;
// @ts-nocheck
import React, { useCallback, useEffect, useRef, useMemo, } from "react";
import { CollageLayout } from "../CollageLayout";
import { CollageCanvas } from "../CollageCanvas";
import { collage } from "./mock";
import styled from "styled-components";
var CollageComponent = function (props) {
    var isFullWindow = props.isFullWindow, isPrint = props.isPrint, setChangingFurnitureId = props.setChangingFurnitureId, isLoadingCollage = props.isLoadingCollage, setIsLoadingCollage = props.setIsLoadingCollage, collageInfo = props.collageInfo, setCollageInfo = props.setCollageInfo;
    // const dispatch = useAppDispatch();
    //const collageInfo = useAppSelector((state) => selectCollage(state));
    var C = useRef({
        canvas: null,
        ctx: null,
        json: "",
        selectedObject: null,
        selectedObjectOffset: null,
    });
    //const [options, setOptions] = useState(null);
    var options = null;
    var layouts = useMemo(function () { return new Map(); }, []);
    //const [layouts,setLayouts] = useState([])
    var rotatedObjectFurniture = useMemo(function () { return new Set(); }, []);
    //const [container, setContainer] = useState(null);
    var distance = 50;
    //const [isRotate, setIsRotate] = useState([]);
    // const [heightNaturalMM, setHeightNaturalMM] = useState(null);
    // const [widthNaturalMM, setWidthNaturalMM] = useState(null);
    var rulerData = useRef({
        heightCanvas: 0,
        yZero: 0,
        yEnd: 0,
        xZero: 0,
        xEnd: 0,
        xStep: 0,
    });
    var ref = useRef();
    var _debug = true;
    var minWallHeight = 2000;
    var printedCollageWidth = 1700; //2000; //1150;
    var handleStartLoadingCollage = useCallback(function () {
        setIsLoadingCollage(true);
    }, []);
    var handleStopLoadingCollage = useCallback(function () {
        setIsLoadingCollage(false);
    }, []);
    var handleLoadCollage = useCallback(function (collageData) {
        // dispatch(setCollageData({ data: collageData, id: 1 }));
        setCollageInfo(collageData);
    }, []);
    var _notFurniture = "decor";
    var debug = function (object) {
        if (_debug) {
            console.log(object);
        }
    };
    useEffect(function () {
        createCanvas();
        if (!isLoadingCollage && !collageInfo.data) {
            // getCollageRequest({
            //   startLoadingCallback: handleStartLoadingCollage,
            //   stopLoadingCallback: handleStopLoadingCollage,
            //   onSuccess: handleLoadCollage,
            //   // onError: commonCallbackError,
            //   //   collage_json_id: 1,
            // });
            handleLoadCollage(collage);
        }
    }, []);
    useEffect(function () {
        if (collageInfo.data &&
            JSON.stringify(collageInfo.data) !== C.current.json) {
            C.current.json = JSON.stringify(collageInfo.data);
            layouts.clear();
            // addJson(structuredClone(collageInfo.data));
            addJson(structuredClone(collage));
        }
    }, [collageInfo.data]);
    var createCanvas = function () {
        if (!C.current.canvas) {
            var newCanvas = new CollageCanvas(ref.current, changeObjectByCoordinate, mooveObjectByCoordinate, selectObjectByCoordinate, clearSelectedObject, options, isPrint, printedCollageWidth);
            var cont = newCanvas.getContext();
            if (isPrint) {
                cont.canvas.width = printedCollageWidth;
            }
            C.current.canvas = newCanvas;
            C.current.ctx = cont;
        }
    };
    var objects = function () {
        var e_1, _a;
        var objects = [];
        try {
            for (var _b = __values(layouts.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var layout = _c.value;
                objects = objects.concat(layout.objects);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return objects;
    };
    var updateObjectByPosition = function (position, objectSetting) { return __awaiter(void 0, void 0, void 0, function () {
        var object;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(position === "wall")) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateWall(objectSetting)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 2:
                    if (!(position === "floor")) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateFloor(objectSetting)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 4: return [4 /*yield*/, getObjectByPosition(position)];
                case 5:
                    object = _a.sent();
                    return [4 /*yield*/, object.updateOptions(objectSetting)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, object.init()];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8: return [4 /*yield*/, resizeCanvasByFirstLayer()];
                case 9:
                    _a.sent();
                    // await tableRender();
                    return [4 /*yield*/, render()];
                case 10:
                    // await tableRender();
                    _a.sent();
                    addRuler();
                    return [2 /*return*/];
            }
        });
    }); };
    // const addButton=(icon, title, funcOnClick)=>{
    //   _control.addButton(icon, title, funcOnClick);
    // }
    // const popupChangeObject =  (type, position, idCurrentObject, like)=> {
    // like = like ?? "";
    // const fancy = Fancybox.show([
    //   {
    //     src:
    //       "/objects/get.php?type=" +
    //       type +
    //       "&position=" +
    //       position +
    //       "&id=" +
    //       idCurrentObject +
    //       "&like=" +
    //       like,
    //     type: "ajax",
    //   },
    // ]);
    // fancy.done = function (fancybox) {
    //   let objects = fancybox.el.querySelectorAll(".object-change");
    //   for (let object of objects) {
    //     object.onclick = (e) => {
    //       let options = JSON.parse(e.currentTarget.dataset.options);
    //       let position = e.currentTarget.dataset.position;
    //       this.updateObjectByPosition(position, options);
    //       fancy.close();
    //     };
    //   }
    // }.bind(this);
    //   };
    var selectObjectByCoordinate = function (x, y) {
        var maxLayout = -1;
        objects().forEach(function (object) {
            if (x >= object.x &&
                x <= object.x + object.width &&
                y >= object.y &&
                y <= object.y + object.height &&
                parseInt(object.layer) > maxLayout) {
                C.current.selectedObject = object;
                C.current.selectedObjectOffset = [x - object.x, y - object.y];
            }
        });
    };
    var clearSelectedObject = function () {
        C.current.selectedObject = null;
        C.current.selectedObjectOffset = null;
    };
    var changeObjectByCoordinate = function (x, y) {
        var maxLayout = -1;
        var objectClick = null;
        objects().forEach(function (object) {
            if (x >= object.x &&
                x <= object.x + object.width &&
                y >= object.y &&
                y <= object.y + object.height &&
                parseInt(object.layer) > maxLayout) {
                objectClick = object;
            }
        });
        if (objectClick) {
            setChangingFurnitureId(objectClick._id);
        }
    };
    var mooveObjectByCoordinate = function (x, y) {
        if (C.current.selectedObject) {
            C.current.selectedObject.x = x - C.current.selectedObjectOffset[0];
            C.current.selectedObject.y = y - C.current.selectedObjectOffset[1];
        }
    };
    var addWall = function (options) {
        if (options === void 0) { options = {}; }
        if (!(options === null || options === void 0 ? void 0 : options.height)) {
            options.height = "100%";
        }
        options.y = 0;
        return addLayout("wall", options);
    };
    var addFloor = function (options) {
        if (options === void 0) { options = {}; }
        if (!options.top) {
            var percent = 25;
            if (options.height) {
                if (options.height.substring(options.height.length - 1) == "%") {
                    percent = parseInt(options.height.substring(0, options.height.length - 1));
                }
            }
            options.y = (100 - percent).toString() + "%";
        }
        return addLayout("floor", options);
    };
    var render = function () {
        var e_2, _a;
        var _b;
        (_b = C.current.ctx) === null || _b === void 0 ? void 0 : _b.reset();
        try {
            for (var _c = __values(layouts.values()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var layout = _d.value;
                layout.render();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    var addLayout = function (name, options) {
        if (options === void 0) { options = {}; }
        var layout = getLayout(name);
        if (!layout) {
            layout = new CollageLayout(C.current.ctx, options, C.current.canvas, isPrint, printedCollageWidth);
            layout.init();
            layout.interiorBox = _this;
            layouts.set(name, layout);
        }
        return layout;
    };
    var resizeCanvasByFirstLayer = function () {
        var layout = getLayout("1");
        if (!layout) {
            return false;
        }
        var sumWidth = distance;
        var maxTop = 0;
        for (var i = 0; i < layout.objects.length; i++) {
            var width = parseInt(layout.objects[i]._options.width);
            sumWidth += width ? width : 0;
            sumWidth += distance;
            var height = (width / layout.objects[i]._widthNatural) *
                layout.objects[i]._heightNatural; // высота объекта в мм
            var top_1 = height + layout.objects[i]._y;
            if (top_1 > maxTop) {
                maxTop = top_1;
            }
        }
        var floor = getLayout("floor");
        var percent = 0.25;
        if (floor._options.height.substring(floor._options.height.length - 1) == "%") {
            percent =
                parseInt(floor._options.height.substring(0, floor._options.height.length - 1)) / 100;
        }
        var wallHeight = Math.max(maxTop, minWallHeight) + distance;
        var canvasHeight = Math.ceil(wallHeight / (1 - percent));
        var a = isPrint ? printedCollageWidth : C.current.canvas._dom.clientWidth;
        C.current.canvas.ratioWidth = a / sumWidth;
        var heightCanvas = canvasHeight * C.current.canvas._ratioWidth;
        C.current.canvas.updateHeight(heightCanvas);
        var newyZero = heightCanvas - heightCanvas * percent;
        /* данные для линейки */
        rulerData.current.heightCanvas = heightCanvas;
        rulerData.current.yZero = newyZero;
        rulerData.current.yEnd = distance * C.current.canvas._ratioWidth;
        rulerData.current.xZero = distance * C.current.canvas._ratioWidth;
        rulerData.current.xEnd =
            (sumWidth - distance) * C.current.canvas._ratioWidth;
        rulerData.current.xStep = C.current.canvas._ratioWidth * 100;
        /* ------------------ */
        debug("Высота канваса: " + canvasHeight + " мм.");
        debug("Ширина канваса: " + sumWidth + " мм.");
        debug("Ширина канваса: " + sumWidth * C.current.canvas._ratioWidth);
        debug("Нулевая координата оси Y: " + newyZero + " px");
        debug("Нулевая координата оси Y: " +
            (heightCanvas - heightCanvas * percent) +
            " px");
        recalcSettingObjects(newyZero);
    };
    var getObjectByPosition = function (positionSearch) {
        var objectResult = null;
        objects().forEach(function (object) {
            if (object.position === positionSearch) {
                objectResult = object;
            }
        });
        return objectResult;
    };
    var recalcSettingObjects = function (yZero) {
        var e_3, _a;
        var layoutFirst = getLayout("1");
        var left = distance * C.current.canvas._ratioWidth;
        layoutFirst.objects.forEach(function (item) {
            if (item._dom.updateData) {
                item._dom.updateData();
            }
            var diffX = item._options.x ? item._options.x : 0;
            item.x = left + diffX * C.current.canvas._ratioWidth;
            item.y =
                yZero -
                    item._height -
                    //Тут подобран коэффицинт чтоб прилично смтрлось при маштабировании, потом надо сдeлать что-то поприличнee
                    //конструкция (1 - (0.7 - item._ratioNatural))
                    item._options.y * (1 - (0.7 - item._ratioNatural));
            left = item.x + item._width + distance * C.current.canvas._ratioWidth;
        });
        try {
            for (var layouts_1 = __values(layouts), layouts_1_1 = layouts_1.next(); !layouts_1_1.done; layouts_1_1 = layouts_1.next()) {
                var layout = layouts_1_1.value;
                if (layout[0] === "wall" || layout[0] === "floor" || layout[0] === "1") {
                    continue;
                }
                layout[1].objects.forEach(function (item) {
                    if (item._dom.updateData) {
                        item._dom.updateData();
                    }
                    if (item.parentPosition) {
                        var objectParent = getObjectByPosition(item.parentPosition);
                        if (objectParent) {
                            if (item._options.x === "left") {
                                item.x = objectParent.x;
                            }
                            else if (item._options.x === "right") {
                                item.x = objectParent.x + objectParent._width - item._width;
                            }
                            else {
                                item.x =
                                    objectParent.x +
                                        objectParent._width / 2 -
                                        item._width / 2 +
                                        item._options.x * C.current.canvas._ratioWidth;
                            }
                            item.y =
                                objectParent.y +
                                    objectParent._height -
                                    item._height -
                                    item._options.y * C.current.canvas._ratioWidth;
                        }
                    }
                });
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (layouts_1_1 && !layouts_1_1.done && (_a = layouts_1.return)) _a.call(layouts_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    var addRuler = function () {
        var correction = 0; // (rulerData.current.yZero - rulerData.current.yEnd) * 0.2;
        /*рисуем линейку*/
        C.current.ctx.beginPath();
        C.current.ctx.lineWidth = 2;
        C.current.ctx.moveTo(rulerData.current.xZero, 
        //rulerData.current.yZero + correction
        rulerData.current.heightCanvas);
        C.current.ctx.lineTo(rulerData.current.xEnd, 
        // rulerData.current.yZero + correction
        rulerData.current.heightCanvas);
        C.current.ctx.strokeStyle = "#ffffff";
        C.current.ctx.stroke();
        C.current.ctx.beginPath();
        C.current.ctx.lineWidth = 2;
        C.current.ctx.moveTo(rulerData.current.xZero, rulerData.current.yZero);
        C.current.ctx.lineTo(rulerData.current.xZero, rulerData.current.yEnd);
        C.current.ctx.strokeStyle = "#ffffff";
        C.current.ctx.stroke();
        for (var i = 0; i <
            (rulerData.current.xEnd - rulerData.current.xZero) /
                rulerData.current.xStep; i++) {
            C.current.ctx.beginPath();
            C.current.ctx.lineWidth = 2;
            var dashHalfLength = 5;
            if (i > 0) {
                if (i % 5 === 0)
                    dashHalfLength = 7;
                if (i % 10 === 0)
                    dashHalfLength = 9;
            }
            C.current.ctx.moveTo(rulerData.current.xZero + rulerData.current.xStep * i, 
            // rulerData.current.yZero + correction - dashHalfLength
            rulerData.current.heightCanvas - dashHalfLength);
            C.current.ctx.lineTo(rulerData.current.xZero + rulerData.current.xStep * i, 
            //rulerData.current.yZero + correction + dashHalfLength
            rulerData.current.heightCanvas);
            C.current.ctx.strokeStyle = "#ffffff";
            C.current.ctx.stroke();
            if (i % 10 === 0) {
                var m = 1;
                if (i * 100 > 9 && i * 100 < 100)
                    m = 2;
                if (i * 100 > 99 && i * 100 < 1000)
                    m = 3;
                if (i * 100 > 999 && i * 100 < 10000)
                    m = 4;
                C.current.ctx.font = "18px Arial";
                C.current.ctx.fillStyle = "#FFFFFF";
                C.current.ctx.fillText(i * 100, rulerData.current.xZero + rulerData.current.xStep * i - 5 * m, 
                //  rulerData.current.yZero + correction + 30
                rulerData.current.heightCanvas - 20);
            }
        }
        for (var i = 0; i <
            (rulerData.current.yZero - rulerData.current.yEnd) /
                rulerData.current.xStep; i++) {
            C.current.ctx.beginPath();
            C.current.ctx.lineWidth = 2;
            var dashHalfLength = 3;
            if (i > 0) {
                if (i % 5 === 0)
                    dashHalfLength = 5;
                if (i % 10 === 0)
                    dashHalfLength = 7;
            }
            C.current.ctx.moveTo(rulerData.current.xZero - dashHalfLength, rulerData.current.yZero - rulerData.current.xStep * i);
            C.current.ctx.lineTo(rulerData.current.xZero + dashHalfLength, rulerData.current.yZero - rulerData.current.xStep * i);
            C.current.ctx.strokeStyle = "#ffffff";
            C.current.ctx.stroke();
        }
    };
    var addJson = function (jsonData) { return __awaiter(void 0, void 0, void 0, function () {
        var prevPosition, prevRotate, prevCoef, countFurnitureOneLayer, i, countRotate, indexOneLayer, i, objectFurniture, layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (jsonData.wall) {
                        addWall(jsonData.wall);
                    }
                    if (jsonData.floor) {
                        addFloor(jsonData.floor);
                    }
                    if (!(jsonData.furniture && jsonData.furniture.constructor === Array)) return [3 /*break*/, 4];
                    prevPosition = null;
                    prevRotate = null;
                    prevCoef = 1;
                    countFurnitureOneLayer = 0;
                    for (i = 0; i < jsonData.furniture.length; i++) {
                        if (jsonData.furniture[i].layer === "1" &&
                            jsonData.furniture[i].type !== _notFurniture) {
                            countFurnitureOneLayer++;
                        }
                    }
                    countRotate = Math.ceil(countFurnitureOneLayer * 0.2);
                    indexOneLayer = 0;
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < jsonData.furniture.length)) return [3 /*break*/, 4];
                    objectFurniture = jsonData.furniture[i];
                    if (!objectFurniture.x &&
                        objectFurniture.type &&
                        jsonData.positions &&
                        jsonData.positions[objectFurniture.type] &&
                        jsonData.positions[objectFurniture.type].x) {
                        objectFurniture.x = jsonData.positions[objectFurniture.type].x;
                    }
                    if (!objectFurniture.y &&
                        objectFurniture.type &&
                        jsonData.positions &&
                        jsonData.positions[objectFurniture.type] &&
                        jsonData.positions[objectFurniture.type].y) {
                        objectFurniture.y = jsonData.positions[objectFurniture.type].y;
                    }
                    layout = addLayout(objectFurniture.layer, {
                        width: "100%",
                        height: "100%",
                    });
                    if (objectFurniture.layer !== "1" && prevPosition) {
                        objectFurniture.parentPosition = prevPosition;
                        objectFurniture.rotation = prevRotate;
                        objectFurniture.width *= prevCoef;
                        objectFurniture.y *= prevCoef;
                    }
                    if (objectFurniture.position && objectFurniture.layer === "1") {
                        prevPosition = objectFurniture.position;
                    }
                    if (!objectFurniture.name && objectFurniture.type) {
                        objectFurniture.name = objectFurniture.type;
                    }
                    if (!objectFurniture.count) {
                        objectFurniture.count = 1;
                    }
                    prevRotate = "front";
                    prevCoef = 1;
                    if (objectFurniture.layer === "1" &&
                        jsonData.furniture[i].type !== _notFurniture) {
                        if (indexOneLayer < countRotate) {
                            prevCoef = 1; // 1.2;
                            objectFurniture.rotation = "left";
                            objectFurniture.width *= prevCoef;
                            objectFurniture.y *= prevCoef;
                            prevRotate = "left";
                        }
                        else if (indexOneLayer >= countFurnitureOneLayer - countRotate) {
                            objectFurniture.rotation = "right";
                            prevCoef = 1; //1.2;
                            objectFurniture.width *= prevCoef;
                            objectFurniture.y *= prevCoef;
                            prevRotate = "right";
                        }
                        indexOneLayer++;
                    }
                    return [4 /*yield*/, layout.addObject(objectFurniture)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    resizeCanvasByFirstLayer();
                    setInterval(function () {
                        render();
                        addRuler();
                    }, 1000 / 30);
                    return [2 /*return*/];
            }
        });
    }); };
    var getLayout = function (key) {
        if (layouts.has(key)) {
            return layouts.get(key);
        }
        return null;
    };
    var updateWall = function (options) { return __awaiter(void 0, void 0, void 0, function () {
        var layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    layout = getLayout("wall");
                    layout.updateOptions(options);
                    return [4 /*yield*/, layout.init()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateFloor = function (options) { return __awaiter(void 0, void 0, void 0, function () {
        var layout;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    layout = getLayout("floor");
                    layout.updateOptions(options);
                    return [4 /*yield*/, layout.init()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, { "$fullWindow": isFullWindow, isPrint: isPrint, className: "landscape" },
        React.createElement("div", { ref: ref })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n  //  transform: ", ";\n  & > div {\n    width: ", ";\n  }\n\n  @media print {\n    display: block;\n    width: 1700px;\n    //height: 100vh;\n    margin: 0;\n    & > div {\n      width: auto !important;\n    }\n    @page {\n      background-color: #ffffff;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n  //  transform: ", ";\n  & > div {\n    width: ", ";\n  }\n\n  @media print {\n    display: block;\n    width: 1700px;\n    //height: 100vh;\n    margin: 0;\n    & > div {\n      width: auto !important;\n    }\n    @page {\n      background-color: #ffffff;\n    }\n  }\n"])), function (p) { return (p.$fullWindow ? "100vw" : "680px"); }, function (p) { return (p.$fullWindow ? "calc(100vh - 60px)" : "auto"); }, function (p) { return (p.$fullWindow ? "0" : "0 0 10px 0"); }, function (p) { return (p.isPrint ? "rotate(90deg)" : ""); }, function (p) { return (p.$fullWindow ? "90% !important" : "100%  !important"); });
var Collage = React.memo(CollageComponent);
export default Collage;
var templateObject_1;
