var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var CurrentUsers = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: 10px;\n    margin-bottom: 10px;\n    padding: 10px;\n    border: 1px solid gray;\n    border-radius: 8px;\n"], ["\n    margin-top: 10px;\n    margin-bottom: 10px;\n    padding: 10px;\n    border: 1px solid gray;\n    border-radius: 8px;\n"])));
export var AdminButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 10px;\n    border: 1px solid #eeeef7;\n    background-color: lightgray;\n    border-radius: 8px;\n"], ["\n    padding: 10px;\n    border: 1px solid #eeeef7;\n    background-color: lightgray;\n    border-radius: 8px;\n"])));
export var AllUsersTable = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 10px;\n    border: 1px solid #eeeef7;\n    background-color: lightgray;\n    border-radius: 8px;\n    margin-bottom: 10px;\n    \n    > div {\n    margin: 8px;\n    }\n"], ["\n    padding: 10px;\n    border: 1px solid #eeeef7;\n    background-color: lightgray;\n    border-radius: 8px;\n    margin-bottom: 10px;\n    \n    > div {\n    margin: 8px;\n    }\n"])));
export var AddUser = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 10px;\n    margin-bottom: 10px;\n"], ["\n    margin-top: 10px;\n    margin-bottom: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
