import React, { memo, useEffect } from "react";
import { getProjectsRequest } from "src/api/requests/get-projects";
import { getDoorsRequest } from "src/api/requests/get-doors";
import { getFurnituresRequests } from "src/api/requests/get-furnitures";
import { getWindowsRequest } from "src/api/requests/get-windows";
import { getRoomTypesRequest } from "src/api/requests/get-room-types";
import { getLayoutsRequest } from "src/api/requests/get-layouts";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from 'src/constants';
export var LoadInfo = memo(function LoadInfo(_a) {
    var handleStartLoadinProjects = _a.handleStartLoadinProjects, handleStopLoadingProjects = _a.handleStopLoadingProjects, handleLoadProjects = _a.handleLoadProjects, handleStartLoadingFurnitures = _a.handleStartLoadingFurnitures, handleStopLoadingFurnitures = _a.handleStopLoadingFurnitures, handleStartLoadingRoomTypes = _a.handleStartLoadingRoomTypes, handleStopLoadingRoomTypes = _a.handleStopLoadingRoomTypes, handleStopLoadingShards = _a.handleStopLoadingShards, handleStartLoadingShards = _a.handleStartLoadingShards, handleLoadWindows = _a.handleLoadWindows, handleLoadDoors = _a.handleLoadDoors, handleLoadRoomTypes = _a.handleLoadRoomTypes, handleLoadFurnitures = _a.handleLoadFurnitures, handleUpdateCatalog = _a.handleUpdateCatalog, isLoadingMeta = _a.isLoadingMeta, handleStartLoadingLayouts = _a.handleStartLoadingLayouts, handleStopLoadingLayouts = _a.handleStopLoadingLayouts, handleLoadLayouts = _a.handleLoadLayouts;
    // load project
    useEffect(function () {
        getProjectsRequest({
            startLoadingCallback: handleStartLoadinProjects,
            stopLoadingCallback: handleStopLoadingProjects,
            onSuccess: handleLoadProjects,
            // onError: commonCallbackError,
        });
        var hasSelectedProject = localStorageWorker.get({ key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_ID });
        if (Boolean(hasSelectedProject)) {
            getLayoutsRequest({
                startLoadingCallback: handleStartLoadingLayouts,
                stopLoadingCallback: handleStopLoadingLayouts,
                onSuccess: handleLoadLayouts,
            });
        }
    }, []);
    // /** Load Furnitures */
    useEffect(function () {
        isLoadingMeta.isStartLoadingMeta();
        getFurnituresRequests({
            startLoadingCallback: handleStartLoadingFurnitures,
            stopLoadingCallback: handleStopLoadingFurnitures,
            onSuccess: handleLoadingDoors,
            // onError: commonCallbackError,
        });
    }, []);
    // загружаем синхронно, чтобы создавать каталог один раз со всеми данными переписать на сагу
    var complitedLoadiDoor = function (door) {
        handleLoadDoors(door);
        handleUpdateCatalog();
    };
    var handleLoadingWidnow = function (windows) {
        handleLoadWindows(windows);
        getDoorsRequest({
            startLoadingCallback: handleStartLoadingShards,
            stopLoadingCallback: handleStopLoadingShards,
            onSuccess: complitedLoadiDoor,
            // onError: commonCallbackError,
        });
    };
    var handleLoadingDoors = function (furniture) {
        handleLoadFurnitures(furniture);
        getWindowsRequest({
            startLoadingCallback: handleStartLoadingShards,
            stopLoadingCallback: handleStopLoadingShards,
            onSuccess: handleLoadingWidnow,
            // onError: commonCallbackError,
        });
    };
    /** Load Room Types */
    useEffect(function () {
        getRoomTypesRequest({
            startLoadingCallback: handleStartLoadingRoomTypes,
            stopLoadingCallback: handleStopLoadingRoomTypes,
            onSuccess: handleLoadRoomTypes,
            // onError: commonCallbackError,
        });
    }, []);
    return React.createElement(React.Fragment, null);
});
