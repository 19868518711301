import { BoxHelper, Box3, ObjectLoader } from "three";
import { loadObjWithMaterial } from "src/demo/src/catalog/utils/load-obj";
import { BALANCER } from "./_constants";
import path from "path";
import convert from "convert-units";
import React from "react";
var cachedJSONSofa = null;
var generateFurnitures = function (furniture) {
    // console.log(furniture)
    // console.log(furniture)
    var mtl = require("./sofa/sofa.mtl");
    var obj = require("./sofa/sofa.obj");
    var img = "".concat(BALANCER).concat(furniture.type_sub.default_image_src);
    var width = { length: Boolean(furniture.width) ? furniture.width / 10 : 180, unit: "cm" };
    var depth = { length: Boolean(furniture.length) ? furniture.length / 10 : 60, unit: "cm" };
    var length = { length: Boolean(furniture.length) ? furniture.length / 10 : 70, unit: "cm" };
    // console.log(furniture)
    var elementFurniture = {
        name: "".concat(furniture.id),
        prototype: "items",
        info: {
            title: furniture.name,
            tag: ["furnishings", "leather"],
            description: "Leather sofa",
            image: "".concat(BALANCER).concat(furniture.type_sub.default_image_src),
            // width: 200,
        },
        // width: width,
        // depth: depth,
        // height: height,
        // zalupa: 'zalupa',
        height: length,
        properties: {
            width: {
                label: "Ширина",
                type: "length-measure",
                defaultValue: {
                    // length: window.width,
                    length: width.length,
                },
            },
            height: {
                label: "Длина",
                type: "length-measure",
                defaultValue: {
                    // length: window.height,
                    length: length.length,
                },
            },
        },
        catalog: furniture,
        // depth: depth,
        // dimensions: {
        // properties: {
        //   width: {
        //     label: "Width",
        //     type: "length-measure",
        //     defaultValue: {
        //       // length: door.width,
        //       length: width,
        //     },
        //   },
        //   height: {
        //     label: "Height",
        //     type: "length-measure",
        //     defaultValue: {
        //       // length: door.height,
        //       length: height,
        //     },
        //   },
        //   // width:  {
        //   //   length: width,
        //   // },
        //   // height: {
        //   //   length: height,
        //   // },
        //   // width: {
        //   //   label: 'width',
        //   //   type: 'width',
        //   //   defaultValue: {
        //   //     length: width,
        //   //     unit: 'cm'
        //   //   }
        //   // },
        //   // height: {
        //   //   label: 'height',
        //   //   type: 'height',
        //   //   defaultValue: {
        //   //     length: height,
        //   //     unit: 'cm'
        //   //   }
        //   // }
        // },
        render2D: function (element, layer, scene) {
            // console.log(element)
            var _a, _b, _c, _d;
            var widthElement = ((_b = (_a = element.properties) === null || _a === void 0 ? void 0 : _a.get('width')) === null || _b === void 0 ? void 0 : _b.get('length')) || width.length;
            var heightElement = ((_d = (_c = element.properties) === null || _c === void 0 ? void 0 : _c.get('height')) === null || _d === void 0 ? void 0 : _d.get('length')) || length.length;
            // console.log( )
            // console.log(element)
            var angle = element.rotation + 90;
            var textRotation = Math.sin((angle * Math.PI) / 180) < 0 ? 180 : 0;
            var style = {
                stroke: element.selected ? "#0096fd" : "#000",
                strokeWidth: "2px",
                fill: "#84e1ce",
            };
            var arrow_style = {
                stroke: element.selected ? "#0096fd" : null,
                strokeWidth: "2px",
                fill: "#84e1ce",
            };
            return (
            // <img src={`${BALANCER}${furniture.icon_src}`} />
            React.createElement("g", { transform: "translate(".concat(-widthElement / 2, ",").concat(-heightElement / 2, ")") },
                React.createElement("image", { x: 0, y: 0, width: widthElement, height: heightElement, href: "".concat(BALANCER).concat(furniture.svg_file), preserveAspectRatio: "none" }),
                React.createElement("text", { x: "0", y: "0", transform: "translate(".concat(widthElement / 2, ", ").concat(heightElement / 2, ") scale(1,-1) rotate(").concat(textRotation, ")"), style: { textAnchor: "middle", fontSize: "11px" } }, element.type)));
        },
        render3D: function (element, layer, scene) {
            var onLoadItem = function (object) {
                var newWidth = convert(width.length).from(width.unit).to(scene.unit);
                var newHeight = convert(length.length).from(length.unit).to(scene.unit);
                var newDepth = convert(depth.length).from(depth.unit).to(scene.unit);
                object.scale.set(newWidth / width.length, newHeight / length.length, newDepth / depth.length);
                var box = new BoxHelper(object, 0x99c3fb);
                box.material.linewidth = 2;
                box.material.depthTest = false;
                box.renderOrder = 1000;
                box.visible = element.selected;
                object.add(box);
                // Normalize the origin of this item
                var boundingBoxBefore = new Box3();
                var boundingBox = boundingBoxBefore.setFromObject(object);
                var center = [
                    (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
                    (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
                    (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z,
                ];
                object.position.x -= center[0];
                object.position.y -=
                    center[1] - (boundingBox.max.y - boundingBox.min.y) / 2;
                object.position.z -= center[2];
                return object;
            };
            if (cachedJSONSofa) {
                var loader = new ObjectLoader();
                var object = loader.parse(cachedJSONSofa);
                return Promise.resolve(onLoadItem(object));
            }
            return loadObjWithMaterial(mtl, obj, path.dirname(img) + "/").then(function (object) {
                cachedJSONSofa = object.toJSON();
                var loader = new ObjectLoader();
                return onLoadItem(loader.parse(cachedJSONSofa));
            });
        },
        updateRender3D: function (element, layer, scene, mesh, oldElement, differences, selfDestroy, selfBuild) {
            var noPerf = function () {
                selfDestroy();
                return selfBuild();
            };
            if (differences.indexOf("selected") !== -1) {
                mesh.traverse(function (child) {
                    if (child instanceof BoxHelper) {
                        child.visible = element.selected;
                    }
                });
                return Promise.resolve(mesh);
            }
            if (differences.indexOf("rotation") !== -1) {
                mesh.rotation.y = (element.rotation * Math.PI) / 180;
                return Promise.resolve(mesh);
            }
            return noPerf();
        },
    };
    // console.log('elementFurniture')
    // console.log(elementFurniture)
    return elementFurniture;
};
export var generateFurniture = function (_a) {
    var catalog = _a.catalog, furniture = _a.furniture;
    // console.log(furniture)
    furniture.forEach(function (item) {
        var elementCatalog = generateFurnitures(item);
        // if(item.furnitures.length){
        //   generateFurniture({catalog, furniture: item.furnitures})
        // }
        // console.log('yessss')
        // console.log(elementCatalog)
        catalog.registerElement(elementCatalog);
    });
};
