var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useEffect, useState } from "react";
import { InputStyled } from "./styled";
export var InputCustom = memo(function InpitCustom(_a) {
    var onChange = _a.onChange, _b = _a.type, type = _b === void 0 ? "text" : _b, placeholder = _a.placeholder, value = _a.value;
    var _c = __read(useState(value), 2), inputValue = _c[0], setInputValue = _c[1];
    useEffect(function () {
        setInputValue(value);
    }, [value]);
    var handleChangeInputValue = useCallback(function (event) {
        var valueFromInput = event.target.value;
        onChange(valueFromInput);
        setInputValue(valueFromInput);
    }, [onChange]);
    return (React.createElement(InputStyled, { value: inputValue, onChange: handleChangeInputValue, placeholder: placeholder, type: type }));
});
