import { fetchGetRequests } from "src/api/fetch/get";
import { furnituresUrl } from "src/api/urls/get-furnitures";
import { responseDataFormatter } from "./response-formatter";
export var getFurnituresRequests = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    fetchGetRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        responseDataFormatter: responseDataFormatter,
        url: furnituresUrl,
        urlId: '?skip=0&limit=1000'
        // data: [],
        /** ONLY_FOR_DEVELOP */
        // mockData: MOCK_NEW_CATEGOR,
    });
};
