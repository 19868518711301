var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, memo, useCallback, useMemo, useEffect, useRef, } from "react";
import { CommonText } from "src/ui-kit/text";
import { SelectBoxStyled, DropdownList, SelectBoxContainer, DropdownItem, } from "./styled";
export var SelectBox = memo(function SelectBox(_a) {
    var options = _a.options, _b = _a.selectedValue, selectedValue = _b === void 0 ? "" : _b, placeholder = _a.placeholder, _c = _a.hasImages, hasImages = _c === void 0 ? false : _c, imageHeight = _a.imageHeight, imageWidth = _a.imageWidth, _d = _a.heightSize, heightSize = _d === void 0 ? 240 : _d, _e = _a.customWidth, customWidth = _e === void 0 ? "200px" : _e, _f = _a.customFontsizeTitle, customFontsizeTitle = _f === void 0 ? "16px" : _f, hardClear = _a.hardClear, onValueChange = _a.onValueChange, handleFalseHardClear = _a.handleFalseHardClear;
    var _g = __read(useState(selectedValue), 2), selectedOption = _g[0], setSelectedOption = _g[1];
    var _h = __read(useState(false), 2), isOpen = _h[0], setIsOpen = _h[1];
    var _j = __read(useState("down"), 2), dropdownDirection = _j[0], setDropdownDirection = _j[1];
    var containerRef = useRef(null);
    useEffect(function () {
        setSelectedOption(selectedValue);
    }, [selectedValue]);
    useEffect(function () {
        if (hardClear) {
            setSelectedOption('');
            handleFalseHardClear();
        }
    }, [hardClear, handleFalseHardClear]);
    // console.log(selectedValue)
    var handleChange = useCallback(function (newValue) {
        var findedOption = options.find(function (option) { return option.value === newValue.value; });
        setIsOpen(false);
        onValueChange(findedOption);
        if (!newValue.isOnlyButton) {
            setSelectedOption(findedOption.label);
        }
    }, [onValueChange, options]);
    var isDisplayPlaceholder = useMemo(function () { return Boolean(placeholder) && !selectedOption; }, [selectedOption, placeholder]);
    var toggleDropdown = useCallback(function () {
        setIsOpen(function (prevState) { return !prevState; });
    }, []);
    var updateDropdownDirectionAndWidth = useCallback(function () {
        if (containerRef.current) {
            var rect = containerRef.current.getBoundingClientRect();
            var windowHeight = window.innerHeight;
            var spaceBelow = windowHeight - rect.bottom;
            var spaceAbove = rect.top;
            var newDirection = spaceBelow < heightSize && spaceAbove > spaceBelow ? "up" : "down";
            setDropdownDirection(newDirection);
        }
    }, [heightSize]);
    useEffect(function () {
        if (isOpen) {
            updateDropdownDirectionAndWidth();
            window.addEventListener("resize", updateDropdownDirectionAndWidth);
            window.addEventListener("scroll", updateDropdownDirectionAndWidth, true);
        }
        else {
            window.removeEventListener("resize", updateDropdownDirectionAndWidth);
            window.removeEventListener("scroll", updateDropdownDirectionAndWidth, true);
        }
        return function () {
            window.removeEventListener("resize", updateDropdownDirectionAndWidth);
            window.removeEventListener("scroll", updateDropdownDirectionAndWidth, true);
        };
    }, [isOpen, updateDropdownDirectionAndWidth]);
    var ArrowDown = function () { return (React.createElement("svg", { width: "1em", height: "0.583em", viewBox: "0 0 24 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: { fontSize: customFontsizeTitle } },
        React.createElement("path", { d: "M10.9393 13.0607C11.5251 13.6464 12.4749 13.6464 13.0607 13.0607L22.6066 3.51472C23.1924 2.92893 23.1924 1.97919 22.6066 1.3934C22.0208 0.807612 21.0711 0.807612 20.4853 1.3934L12 9.87868L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807612 1.97918 0.807612 2.92893 1.3934 3.51472L10.9393 13.0607ZM10.5 11L10.5 12L13.5 12L13.5 11L10.5 11Z", fill: "black", transform: "rotate(180 12 7)" }))); };
    var ArrowUp = function () { return (React.createElement("svg", { width: "1em", height: "0.583em", viewBox: "0 0 24 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: { fontSize: customFontsizeTitle } },
        React.createElement("path", { d: "M10.9393 13.0607C11.5251 13.6464 12.4749 13.6464 13.0607 13.0607L22.6066 3.51472C23.1924 2.92893 23.1924 1.97919 22.6066 1.3934C22.0208 0.807612 21.0711 0.807612 20.4853 1.3934L12 9.87868L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807612 1.97918 0.807612 2.92893 1.3934 3.51472L10.9393 13.0607ZM10.5 11L10.5 12L13.5 12L13.5 11L10.5 11Z", fill: "black" }))); };
    var closeDropdown = useCallback(function () {
        setIsOpen(false);
    }, []);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (containerRef.current &&
                !containerRef.current.contains(event.target)) {
                closeDropdown();
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, closeDropdown]);
    return (React.createElement(SelectBoxContainer, { ref: containerRef },
        React.createElement(SelectBoxStyled, { "$width": customWidth, onClick: toggleDropdown },
            isDisplayPlaceholder ? (React.createElement(CommonText, { fontSize: customFontsizeTitle, text: placeholder })) : (React.createElement(CommonText, { fontSize: customFontsizeTitle, text: selectedOption })),
            isOpen ? React.createElement(ArrowUp, null) : React.createElement(ArrowDown, null)),
        isOpen && (React.createElement(DropdownList, { "$maxHeight": heightSize, "$direction": dropdownDirection }, options.map(function (option) { return (React.createElement(DropdownItem, { key: option.id, onClick: function () { return handleChange(option); } },
            hasImages && option.image && (React.createElement("img", { height: imageHeight, width: imageWidth, src: option.image, alt: option.label })),
            React.createElement(CommonText, { text: option.label }))); })))));
});
