import { LOCAL_STRORAGE_KEYS } from "src/constants";
export var localStorageWorker = {
    set: function (_a) {
        var key = _a.key, value = _a.value;
        try {
            localStorage.setItem(key, value);
        }
        catch (error) {
            console.error('Ошибка при установке значения в localStorage:', error);
        }
    },
    get: function (_a) {
        var key = _a.key;
        try {
            return localStorage.getItem(key);
        }
        catch (error) {
            console.error('Ошибка при получении значения из localStorage:', error);
            return null;
        }
    },
    remove: function (_a) {
        var key = _a.key;
        try {
            localStorage.removeItem(key);
        }
        catch (error) {
            console.error('Ошибка при удалении значения из localStorage:', error);
        }
    },
    removeAllItems: function () {
        try {
            Object.values(LOCAL_STRORAGE_KEYS).forEach(function (key) {
                localStorage.removeItem(key);
            });
        }
        catch (error) {
            console.error('Ошибка при удалении значений из localStorage:', error);
        }
    }
};
