var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useMemo, useState } from "react";
import { Modal } from "src/ui-kit/modal";
import { InputCustom } from "src/ui-kit/Input";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { CurrentUsers, AdminButton, AllUsersTable, AddUser } from "./styled";
var addProjectButtonValue = "addButton";
export var Admin = memo(function Admin(_a) {
    var handleCloseModalAdmin = _a.handleCloseModalAdmin, isOpenAdminModal = _a.isOpenAdminModal, handleAddUser = _a.handleAddUser, handleGetUsers = _a.handleGetUsers, 
    // handleGetProject,
    currentUsers = _a.currentUsers, handleGetAllProjects = _a.handleGetAllProjects, getProjectById = _a.getProjectById, allProjects = _a.allProjects, handleUpdateProjectById = _a.handleUpdateProjectById, isLoadingAdminAction = _a.isLoadingAdminAction;
    var _b = __read(useState(""), 2), newUserName = _b[0], setNewUserName = _b[1];
    var _c = __read(useState(""), 2), newUserPassword = _c[0], setNewUserPassword = _c[1];
    var _d = __read(useState(true), 2), isDisplayUsers = _d[0], setIsDisplayUsers = _d[1];
    var _e = __read(useState(false), 2), isDisplayAddUser = _e[0], setIsDisplayAddUser = _e[1];
    var _f = __read(useState(false), 2), isDisplayAllProjects = _f[0], setIsDisplayAllProjects = _f[1];
    var _g = __read(useState(null), 2), startEditNameProjectId = _g[0], setStartEditNameProjectId = _g[1];
    var _h = __read(useState(""), 2), newNameProject = _h[0], setNewNameProject = _h[1];
    var allProjectsSorted = allProjects.sort(function (a, b) { return a.id - b.id; });
    var handlesStartEditNameProjectId = function (id) {
        setStartEditNameProjectId(id);
    };
    var handleOpenDisplayAllProjects = function () {
        setIsDisplayAllProjects(true);
    };
    var handleCloseDisplayAllProjects = function () {
        setIsDisplayAllProjects(false);
    };
    var handleOpenDisplayAddUser = function () {
        setIsDisplayAddUser(true);
    };
    var handleCloseDisplayAddUser = function () {
        setIsDisplayAddUser(false);
    };
    var handleGetUsersData = function () {
        setIsDisplayUsers(true);
        handleGetUsers();
    };
    var handleUpdateNewUserPassword = function (password) {
        setNewUserPassword(password);
    };
    var handleUpdateNewUserName = function (name) {
        setNewUserName(name);
    };
    var clearNamePassword = function () {
        setNewUserName("");
        setNewUserPassword("");
    };
    var handleAddNewUser = function () {
        var callbackOnSuccess = function () {
            clearNamePassword();
            handleGetUsers();
        };
        handleAddUser({
            name: newUserName,
            password: newUserPassword,
            callbackOnSuccess: callbackOnSuccess,
        });
    };
    var handleCloseUserTable = function () {
        setIsDisplayUsers(false);
    };
    var isDisplayUsersTable = useMemo(function () {
        return Boolean(currentUsers.length) && isDisplayUsers;
    }, [isDisplayUsers, currentUsers.length]);
    var closeAllDisplay = function () {
        setIsDisplayUsers(false);
        handleCloseDisplayAllProjects();
        handleCloseDisplayAddUser();
    };
    var onCloseModalAdmin = function () {
        handleCloseModalAdmin();
        closeAllDisplay();
    };
    var onGetAllProjects = function () {
        handleOpenDisplayAllProjects();
        handleGetAllProjects();
    };
    var handleEditNameProject = function (name) {
        setNewNameProject(name);
    };
    var handleSaveEditProject = function () {
        var callbackOnSuccess = function () {
            setStartEditNameProjectId(null);
            handleGetAllProjects();
        };
        handleUpdateProjectById({
            name: newNameProject,
            id: startEditNameProjectId,
            callbackOnSuccess: callbackOnSuccess,
        });
    };
    var handleRefreshProjectById = function (id) {
        getProjectById(id);
    };
    var isDisabledAddUser = useMemo(function () { return !newUserPassword || !newUserName; }, [newUserPassword, newUserName]);
    return (React.createElement(Modal, { isOpen: isOpenAdminModal, onClose: onCloseModalAdmin },
        React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: handleGetUsersData },
            React.createElement(AdminButton, null,
                React.createElement(CommonText, { text: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0432\u0441\u0435\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439" }))),
        isDisplayUsersTable && (React.createElement(CurrentUsers, null,
            React.createElement("div", null,
                React.createElement(CommonText, { fontSize: "18", text: "Текущие пользователи:" })),
            React.createElement("div", null, currentUsers.map(function (user, index) {
                return (React.createElement(AllUsersTable, { key: "".concat(user.id) },
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u2116: " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(index + 1) })),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "ID: " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(user.id) })),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "is_active: " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(user.is_active) })),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "name: " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(user.name) }))));
            })),
            React.createElement("div", null,
                React.createElement(CommonButton, { onClick: handleCloseUserTable },
                    React.createElement(AdminButton, null,
                        React.createElement(CommonText, { text: "\u0421\u043A\u0440\u044B\u0442\u044C" })))))),
        isDisplayAddUser && (React.createElement(AddUser, null,
            React.createElement(CommonText, { text: "\u0418\u043C\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" }),
            React.createElement(InputCustom, { value: newUserName, onChange: handleUpdateNewUserName }),
            React.createElement(CommonText, { text: "\u041F\u0430\u0440\u043E\u043B\u044C" }),
            React.createElement(InputCustom, { value: newUserPassword, onChange: handleUpdateNewUserPassword }),
            React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: handleAddNewUser, isDisabled: isDisabledAddUser },
                React.createElement(AdminButton, null,
                    React.createElement(CommonText, { text: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }))),
            React.createElement(CommonButton, { onClick: handleCloseDisplayAddUser },
                React.createElement(AdminButton, null,
                    React.createElement(CommonText, { text: "\u0421\u043A\u0440\u044B\u0442\u044C" }))))),
        !isDisplayAddUser && (React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: handleOpenDisplayAddUser },
            React.createElement(AdminButton, null,
                React.createElement(CommonText, { text: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" })))),
        React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: onGetAllProjects },
            React.createElement(AdminButton, null,
                React.createElement(CommonText, { text: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0432\u0441\u0435 \u043F\u0440\u043E\u0435\u043A\u0442\u044B" }))),
        isDisplayAllProjects && (React.createElement("div", null,
            allProjectsSorted.map(function (project, index) {
                var isStartEdit = startEditNameProjectId === project.id;
                return (React.createElement(AllUsersTable, { key: project.id },
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u2116 " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(index + 1) })),
                    isStartEdit ? (React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 " }),
                        React.createElement(InputCustom, { onChange: handleEditNameProject, value: project.name }))) : (React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 " }),
                        React.createElement(CommonText, { color: "red", text: project.name }))),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u0410\u0432\u0442\u043E\u0440 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(project.author_id) })),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "ID \u041F\u0440\u043E\u0435\u043A\u0442\u0430 " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(project.id) })),
                    React.createElement("div", null,
                        React.createElement(CommonText, { fontWeight: "600", text: "\u0414\u0430\u0442\u0430 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044F " }),
                        React.createElement(CommonText, { color: "red", text: "".concat(project.created_at) })),
                    React.createElement("div", null,
                        React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: function () { return handleRefreshProjectById(project.id); } },
                            React.createElement(AdminButton, null,
                                React.createElement(CommonText, { text: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u043E id" })))),
                    React.createElement("div", null, isStartEdit ? (React.createElement(CommonButton, { isLoading: isLoadingAdminAction, onClick: handleSaveEditProject },
                        React.createElement(AdminButton, null,
                            React.createElement(CommonText, { text: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })))) : (React.createElement(CommonButton, { onClick: function () { return handlesStartEditNameProjectId(project.id); } },
                        React.createElement(AdminButton, null,
                            React.createElement(CommonText, { text: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })))))));
            }),
            React.createElement(CommonButton, { onClick: handleCloseDisplayAllProjects },
                React.createElement(AdminButton, null,
                    React.createElement(CommonText, { text: "\u0421\u043A\u0440\u044B\u0442\u044C" })))))));
});
