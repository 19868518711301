import React, { memo, useMemo } from 'react';
import { SelectBox } from "src/ui-kit/select-box";
export var Examples = memo(function Examples(_a) {
    var examplesData = _a.examplesData, onSelectExample = _a.onSelectExample;
    var getSelectOptions = useMemo(function () {
        return examplesData.map(function (example) {
            return {
                id: example.id,
                label: example.name,
                value: example.id,
                image: example.image,
            };
        });
    }, [examplesData]);
    return (React.createElement(SelectBox, { placeholder: "\u0428\u0430\u0431\u043B\u043E\u043D\u044B", onValueChange: onSelectExample, options: getSelectOptions, hasImages: true, imageHeight: "30px", imageWidth: "30px" }));
});
