let shortid = require("shortid");

export class IDBroker {
  static acquireID() {
    let id = shortid.generate();

    // Проверка, чтобы ID не начинался с '_'
    while (id.startsWith("_")) {
      id = shortid.generate();
    }

    return id;
  }
}

export default IDBroker;
