import React, { memo } from "react";
export var RefreshSvg = memo(function RefreshSvg() {
    return (React.createElement("svg", { width: "76", height: "76", viewBox: "0 0 76 76", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d_1618_21253)" },
            React.createElement("rect", { x: "15", y: "15", width: "46", height: "46", rx: "10", fill: "#8DC646" }),
            React.createElement("path", { d: "M49.0576 29.2213L47.0552 27.2189L52.7812 27.2128L52.7789 25L43.3758 25.01V34.4043H45.5885V28.8815L47.493 30.7859C48.9665 32.2596 49.9703 34.1369 50.3777 36.1806C50.7851 38.2244 50.5778 40.3431 49.7819 42.2691C48.986 44.1952 47.6373 45.8422 45.9061 47.0023C44.1748 48.1624 42.1387 48.7835 40.0547 48.7872L40.0586 51C42.5799 50.9955 45.0433 50.2441 47.1378 48.8405C49.2324 47.437 50.8641 45.4443 51.8269 43.1141C52.7898 40.7839 53.0406 38.2207 52.5477 35.748C52.0548 33.2754 50.8403 31.0042 49.0576 29.2213Z", fill: "white" }),
            React.createElement("path", { d: "M36.7392 27.2128L36.7353 25C34.214 25.0045 31.7506 25.7559 29.656 27.1595C27.5615 28.563 25.9298 30.5557 24.9669 32.8859C24.0041 35.2161 23.7533 37.7793 24.2462 40.252C24.739 42.7246 25.9535 44.9958 27.7363 46.7787L29.7448 48.7872H24.0138V51H33.4181V41.5957H31.2053V47.1185L29.3009 45.2141C27.8274 43.7404 26.8235 41.8631 26.4162 39.8194C26.0088 37.7756 26.2161 35.6569 27.012 33.7309C27.8078 31.8048 29.1565 30.1578 30.8878 28.9977C32.619 27.8376 34.6552 27.2165 36.7392 27.2128Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1618_21253", x: "0", y: "0", width: "76", height: "76", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", null),
                React.createElement("feGaussianBlur", { stdDeviation: "7.5" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1618_21253" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1618_21253", result: "shape" })))));
});
