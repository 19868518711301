import { IS_START_LOADING_META, IS_STOP_LOADING_META } from "../constants";

export function isStopLoadingMeta() {
  return {
    type: IS_STOP_LOADING_META,
  };
}

export function isStartLoadingMeta() {
  return {
    type: IS_START_LOADING_META,
  };
}
