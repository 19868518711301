import { IS_START_LOADING_META , IS_STOP_LOADING_META} from '../constants'
export default function (state, action) {
  switch (action.type) {

    case IS_STOP_LOADING_META:
        return state.set('isLoading', false);

    case IS_START_LOADING_META:
        return state.set('isLoading', true);

    default:
      return state;
  }
}
