export var formatPriceSpaces = function (price) {
    var numberString = price.toString();
    var formattedNumber = "";
    for (var i = numberString.length - 1, j = 0; i >= 0; i--, j++) {
        formattedNumber = numberString[i] + formattedNumber;
        if (j % 3 === 2 && i !== 0) {
            formattedNumber = " " + formattedNumber;
        }
    }
    return formattedNumber;
};
