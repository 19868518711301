import { BALANCER, BASE_URL } from './_constants';
// Получаем url апишки в  зависимости от окружения
export var getUrlWithEnv = function (_a) {
    var url = _a.url, _b = _a.balancer, balancer = _b === void 0 ? '' : _b;
    var urlWithBalancer = balancer ? "".concat(balancer).concat(url) : "".concat(BALANCER).concat(url);
    // if (process.env.NODE_ENV === 'production') {
    if (true) {
        return urlWithBalancer;
    }
    else {
        return "".concat(BASE_URL).concat(balancer).concat(url);
    }
};
