var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { Z_INDEX } from "../../../../../constants";
export var MainHeaderIconStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  right: 20px;\n  position: absolute;\n"], ["\n  display: flex;\n  right: 20px;\n  position: absolute;\n"])));
export var IconGroupContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  width: 410px;\n  height: 45px;\n  box-shadow: 4px 4px 20px 0 rgba(212, 212, 212, 0.5);\n  margin-top: 20px;\n  border-radius: 20px;\n  background: #fff;\n  margin-left: 20px;\n  z-index: ", ";\n\n  @media (max-width: 780px) {\n    display: none;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  width: 410px;\n  height: 45px;\n  box-shadow: 4px 4px 20px 0 rgba(212, 212, 212, 0.5);\n  margin-top: 20px;\n  border-radius: 20px;\n  background: #fff;\n  margin-left: 20px;\n  z-index: ", ";\n\n  @media (max-width: 780px) {\n    display: none;\n  }\n"])), Z_INDEX.nine);
export var Icon = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  margin: 0 5px;\n"], ["\n  width: 30px;\n  height: 30px;\n  margin: 0 5px;\n"])));
export var FirstGroupIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:hover {\n    background-color: #8dc646;\n    border-radius: 7px;\n  }\n"], ["\n  &:hover {\n    background-color: #8dc646;\n    border-radius: 7px;\n  }\n"])));
export var IconGroup = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 140px;\n  height: 30px;\n"], ["\n  width: 140px;\n  height: 30px;\n"])));
export var DarkGreenIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  background: #8dc64666;\n  border-radius: 7px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  &:hover {\n    background-color: #8dc646;\n    border-radius: 7px;\n  }\n"], ["\n  width: 30px;\n  height: 30px;\n  background: #8dc64666;\n  border-radius: 7px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  &:hover {\n    background-color: #8dc646;\n    border-radius: 7px;\n  }\n"])));
export var HorizontalBar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 20px;\n  height: 1px;\n  background: #000;\n  margin-top: 5px;\n"], ["\n  width: 20px;\n  height: 1px;\n  background: #000;\n  margin-top: 5px;\n"])));
export var VerticalBar = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 0.8px;\n  height: 7px;\n  background: #000;\n  // position: absolute;\n"], ["\n  width: 0.8px;\n  height: 7px;\n  background: #000;\n  // position: absolute;\n"])));
export var Text = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #000;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 6px;\n  margin-bottom: 7px;\n  font-weight: 600;\n  position: absolute;\n"], ["\n  color: #000;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 6px;\n  margin-bottom: 7px;\n  font-weight: 600;\n  position: absolute;\n"])));
export var MainHeaderContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: auto;\n  height: 50px;\n  margin-top: 10px;\n  z-index: ", ";\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    margin-right: auto;\n    padding: 10px;\n    width: 100%;\n    box-sizing: border-box;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: auto;\n  height: 50px;\n  margin-top: 10px;\n  z-index: ", ";\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    margin-right: auto;\n    padding: 10px;\n    width: 100%;\n    box-sizing: border-box;\n  }\n"])), Z_INDEX.nine);
export var SecondIconGroupContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: 305px;\n  height: 50px;\n  background: #fff;\n  border-radius: 10px 10px 10px 10px;\n  margin-left: 20px;\n  padding: 0 5px;\n  margin-top: 20px;\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    width: 50px;\n    height: auto;\n    max-height: 280px;\n    margin-left: auto;\n    margin-right: 5mm;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: 305px;\n  height: 50px;\n  background: #fff;\n  border-radius: 10px 10px 10px 10px;\n  margin-left: 20px;\n  padding: 0 5px;\n  margin-top: 20px;\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    width: 50px;\n    height: auto;\n    max-height: 280px;\n    margin-left: auto;\n    margin-right: 5mm;\n  }\n"])));
export var Text2D = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  // color: #8dc646;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 20px;\n  font-style: normal;\n  transform: rotate(90deg);\n  font-weight: 700;\n  line-height: normal;\n  position: absolute;\n\n  // ", ":hover & {\n  //   color: #fff;\n  // }\n\n  @media (max-width: 780px) {\n    transform: rotate(0deg);\n  }\n"], ["\n  // color: #8dc646;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 20px;\n  font-style: normal;\n  transform: rotate(90deg);\n  font-weight: 700;\n  line-height: normal;\n  position: absolute;\n\n  // ", ":hover & {\n  //   color: #fff;\n  // }\n\n  @media (max-width: 780px) {\n    transform: rotate(0deg);\n  }\n"])), SecondIconGroupContainer);
export var FirstGreenIcon = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 45px;\n  height: 50px;\n  transform: rotate(-90deg);\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  border-radius: 10px 10px 0 0;\n\n  &:hover {\n    //background-color: #8DC646;\n    color: #fff;\n\n    ", " {\n      color: #fff;\n    }\n  }\n\n  @media (max-width: 780px) {\n    transform: rotate(0deg);\n  }\n"], ["\n  width: 45px;\n  height: 50px;\n  transform: rotate(-90deg);\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  border-radius: 10px 10px 0 0;\n\n  &:hover {\n    //background-color: #8DC646;\n    color: #fff;\n\n    ", " {\n      color: #fff;\n    }\n  }\n\n  @media (max-width: 780px) {\n    transform: rotate(0deg);\n  }\n"])), Text2D);
export var GreenBar = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 1px;\n  height: 50px;\n  background: #8dc646;\n  @media (max-width: 780px) {\n    width: 50px;\n    height: 1px;\n  }\n"], ["\n  width: 1px;\n  height: 50px;\n  background: #8dc646;\n  @media (max-width: 780px) {\n    width: 50px;\n    height: 1px;\n  }\n"])));
export var activeStyleImage = css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background-color: #8dc646;\n  min-width: 50px;\n  & > img {\n    filter: brightness(0) invert(1);\n  }\n\n  & > div {\n    color: #fff !important;\n  }\n  & svg path {\n    stroke: #ffffff;\n  }\n"], ["\n  background-color: #8dc646;\n  min-width: 50px;\n  & > img {\n    filter: brightness(0) invert(1);\n  }\n\n  & > div {\n    color: #fff !important;\n  }\n  & svg path {\n    stroke: #ffffff;\n  }\n"])));
export var IconWrapper = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  background-color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 50px;\n  height: 50px;\n  border-radius: 10px;\n  box-sizing: border-box;\n  color: #8dc646;\n  ", "\n  &:hover {\n    background-color: #8dc646;\n    min-width: 50px;\n\n    & > img {\n      filter: brightness(0) invert(1);\n    }\n\n    & > div {\n      color: #fff;\n    }\n    & svg path {\n      stroke: #ffffff;\n    }\n  }\n"], ["\n  background-color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 50px;\n  height: 50px;\n  border-radius: 10px;\n  box-sizing: border-box;\n  color: #8dc646;\n  ", "\n  &:hover {\n    background-color: #8dc646;\n    min-width: 50px;\n\n    & > img {\n      filter: brightness(0) invert(1);\n    }\n\n    & > div {\n      color: #fff;\n    }\n    & svg path {\n      stroke: #ffffff;\n    }\n  }\n"])), function (props) { return props.isSelected && activeStyleImage; });
export var ThirdIconGroupContainer = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n\n  margin-top: 20px;\n  margin-left: 20px;\n  border-radius: 10px;\n  background: #fff;\n  padding: 0 5px;\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    width: 50px;\n    height: auto;\n    max-height: 280px;\n    margin-left: auto;\n    margin-right: 5mm;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n\n  margin-top: 20px;\n  margin-left: 20px;\n  border-radius: 10px;\n  background: #fff;\n  padding: 0 5px;\n\n  @media (max-width: 780px) {\n    flex-direction: column;\n    width: 50px;\n    height: auto;\n    max-height: 280px;\n    margin-left: auto;\n    margin-right: 5mm;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
