class CollageCanvas {
  // _interiorBox = null;
  // _isDragable = false;
  // _container = null;
  // _dom = null;
  // _heightAdaptive = true;
  // _ratioWidth = 1; //соотношение реальной ширины в мм. к пикселям на экране. Учавствует, когда мы преобразуем ширину объекта из реального размера в пиксели

  constructor(
    container,
    changeObjectByCoordinate,
    mooveObjectByCoordinate,
    selectObjectByCoordinate,
    clearSelectedObject,
    options = {},
    isPrint = false,
    printedCollageWidth = 0
  ) {
    this._interiorBox = null;
    this._isDragable = false;
    this._container = null;
    this._dom = null;
    this._heightAdaptive = true;
    this._ratioWidth = 1; //соотношение реальной ширины в мм. к пикселям на экране. Учавствует, когда мы преобразуем ширину объекта из реального размера в пиксели

    this._container = container;
    this.changeObjectByCoordinate = changeObjectByCoordinate;
    this.mooveObjectByCoordinate = mooveObjectByCoordinate;
    this.selectObjectByCoordinate = selectObjectByCoordinate;
    this.clearSelectedObject = clearSelectedObject;
    let canvas;

    if (isPrint) {
      canvas = document.getElementById("canvasInteriorPrint")
        ? document.getElementById("canvasInteriorPrint")
        : document.createElement("canvas");
      canvas.id = "canvasInteriorPrint";
    } else {
      canvas = document.getElementById("canvasInterior")
        ? document.getElementById("canvasInterior")
        : document.createElement("canvas");
      canvas.id = "canvasInterior";
    }

    canvas.classList.add("canvas-interior");
    canvas.width = isPrint ? printedCollageWidth : container.clientWidth;
    canvas.height = container.clientHeight;
    canvas.ondblclick = this.dblclick.bind(this);
    canvas.onmousedown = this.mousedown.bind(this);
    canvas.onmouseup = this.mouseup.bind(this);
    canvas.onmousemove = this.mousemove.bind(this);
    this._container.appendChild(canvas);
    this._dom = canvas;
  }

  getContext() {
    return this._dom.getContext("2d");
  }

  dblclick(e) {
    e.preventDefault();
    let bbox = this._dom.getBoundingClientRect();
    let x = e.clientX - bbox.left * (this._dom.width / bbox.width);
    let y = e.clientY - bbox.top * (this._dom.height / bbox.height);
    this.changeObjectByCoordinate(x, y);
  }

  mousedown(e) {
    e.preventDefault();
    this._isDragable = true;
    let bbox = this._dom.getBoundingClientRect();
    let x = e.clientX - bbox.left * (this._dom.width / bbox.width);
    let y = e.clientY - bbox.top * (this._dom.height / bbox.height);
    this.selectObjectByCoordinate(x, y);
  }
  mouseup(e) {
    e.preventDefault();
    this._isDragable = false;
    this.clearSelectedObject();
  }
  mousemove(e) {
    e.preventDefault();
    this._isDragable = true;
    let bbox = this._dom.getBoundingClientRect();
    let x = e.clientX - bbox.left * (this._dom.width / bbox.width);
    let y = e.clientY - bbox.top * (this._dom.height / bbox.height);
    this.mooveObjectByCoordinate(x, y);
  }

  set ratioWidth(value) {
    this._ratioWidth = value;
  }

  get ratioWidth() {
    return this._ratioWidth;
  }

  updateHeight(height) {
    if (!this._heightAdaptive) {
      return false;
    }
    this._container.style.height = height + "px";
    this._dom.height = height;
  }
}

export { CollageCanvas };
