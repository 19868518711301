import React from "react";
import * as Three from "three";
import { loadObjWithMaterial } from "src/demo/src/catalog/utils/load-obj";
import path from "path";
import { BALANCER } from './_constants';
var cached3DWindow = null;
var generateWindows = function (window) {
    var width = window.width || 90;
    var height = window.height || 100;
    var altitude = window.altitude || 90;
    var thickness = window.thickness || 10;
    var elementDoor = {
        name: "window-".concat(window.id),
        prototype: "holes",
        info: {
            title: window.name,
            tag: ["window"],
            description: window.type,
            image: "".concat(BALANCER).concat(window.icon_src_preview),
        },
        properties: {
            width: {
                label: "Ширина",
                type: "length-measure",
                defaultValue: {
                    // length: window.width,
                    length: width,
                },
            },
            height: {
                label: "Высота",
                type: "length-measure",
                defaultValue: {
                    // length: window.height,
                    length: height,
                },
            },
            altitude: {
                label: "Высота от пола",
                type: "length-measure",
                defaultValue: {
                    // length: door.altitude,
                    length: altitude,
                },
            },
            thickness: {
                label: "Толщина",
                type: "length-measure",
                defaultValue: {
                    // length: window.thickness,
                    length: thickness,
                },
            },
        },
        render2D: function (element, layer, scene) {
            var STYLE_HOLE_BASE = {
                // stroke: "white",
                strokeWidth: "3px",
                // fill: "#000",
                fill: 'none'
            };
            var STYLE_HOLE_SELECTED = {
                stroke: "#0096fd",
                strokeWidth: "3px",
                // fill: "#0096fd",
                cursor: "move",
                fill: "none"
            };
            //let line = layer.lines.get(hole.line);
            var epsilon = element.properties.get('thickness').get("length") / 2;
            // let epsilon = 3;
            // console.log(element.properties.get('thickness'))
            // console.log(epsilon)
            var holeWidth = element.properties.get("width").get("length");
            var holePath = "M".concat(0, " ").concat(-epsilon, "  L").concat(holeWidth, " ").concat(-epsilon, "  L").concat(holeWidth, " ").concat(epsilon, "  L").concat(0, " ").concat(epsilon, "  z");
            var holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
            var length = element.properties.get("width").get("length");
            return (React.createElement("g", { transform: "translate(".concat(-length / 2, ", 0)") },
                React.createElement("rect", { x: 0, y: -epsilon / 2, width: holeWidth, height: epsilon, style: holeStyle, preserveAspectRatio: "none" }),
                React.createElement("image", { x: 0, y: -epsilon / 2, width: holeWidth, height: epsilon, href: "".concat(BALANCER).concat(window.icon_src), preserveAspectRatio: "none", style: holeStyle })));
        },
        render3D: function (element, layer, scene) {
            var onLoadItem = function (object) {
                var boundingBoxBefore = new Three.Box3();
                var boundingBox = boundingBoxBefore.setFromObject(object);
                var initialWidth = boundingBox.max.x - boundingBox.min.x;
                var initialHeight = boundingBox.max.y - boundingBox.min.y;
                var initialThickness = boundingBox.max.z - boundingBox.min.z;
                if (element.selected) {
                    var box = new Three.BoxHelper(object, 0x99c3fb);
                    box.material.linewidth = 2;
                    box.material.depthTest = false;
                    box.renderOrder = 1000;
                    object.add(box);
                }
                var width = element.properties.get("width").get("length");
                var height = element.properties.get("height").get("length");
                var thickness = element.properties.get("thickness").get("length");
                object.scale.set(width / initialWidth, height / initialHeight, thickness / initialThickness);
                return object;
            };
            if (cached3DWindow) {
                return Promise.resolve(onLoadItem(cached3DWindow.clone()));
            }
            var mtl = require("./window/window.mtl");
            var obj = require("./window/window.obj");
            var img = "".concat(BALANCER).concat(window.icon_src_preview);
            return loadObjWithMaterial(mtl, obj, path.dirname(img) + "/").then(function (object) {
                cached3DWindow = object;
                return onLoadItem(cached3DWindow.clone());
            });
        },
    };
    return elementDoor;
};
export var generateWindow = function (_a) {
    var window = _a.window, catalog = _a.catalog;
    var holes = [];
    window.forEach(function (item) {
        var elementCatalog = generateWindows(item);
        holes.push(elementCatalog);
        catalog.registerElement(elementCatalog);
    });
    return holes;
};
