import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Translator from "./translator/translator";
import Catalog from "./catalog/catalog";
import actions from "./actions/export";
import { objectsMap } from "./utils/objects-utils";
import {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
} from "./components/export";
import { VERSION } from "./version";
import { TopMenu } from "./components/functional-components/topMenu";
import { LoadInfo } from "./components/functional-components/load-info";
import { Meta } from "./components/functional-components/meta";
import "./styles/export";

import Collage from "./components/CollageComponens/Collage";
import { mock } from "./mock";
import { Estimates } from "./components/estimates";
import { collage } from "./components/CollageComponens/Collage/mock";
import { PrintedComponent } from "./components/printed-component";
import { MainMenu } from "./components/functional-components/mainMenu";
import { LoaderIcon } from "src/ui-kit/loader-icon";
const { Toolbar } = ToolbarComponents;
const { Sidebar } = SidebarComponents;
const { FooterBar } = FooterBarComponents;

const toolbarW = 50;
// const sidebarW = 300;
const sidebarW = 0;
const footerBarH = 60;

const wrapperStyle = {
  display: "flex",
  flexFlow: "row nowrap",
};

const containerStyle = {
  height: "100%",
  overflow: "hidden",
  backgroundColor: "#F5F5F5"
};

const overlayStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  top: 0,
  left: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#80808069",
};

class ReactPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collageInfo: { data: null },
      isOpenScene: true,
      isOpenCollageAndEstimates: false,
      isOpenCollage: false,
      isOpenEvaluates: false,
      isPrinting: false,
    };
    this.setCollageInfo = this.setCollageInfo.bind(this);
    this.setIsOpenScene = this.setIsOpenScene.bind(this);
    this.setIsOpenCollageAndEstimates =
      this.setIsOpenCollageAndEstimates.bind(this);
    this.setIsOpenCollage = this.setIsOpenCollage.bind(this);
    this.setIsOpenEvaluates = this.setIsOpenEvaluates.bind(this);
    this.setIsPrinting = this.setIsPrinting.bind(this);

    // this.refPrintedComponent = createRef();
  }
  setIsPrinting(newValue) {
    this.setState({ isPrinting: newValue });
  }

  setCollageInfo(newData) {
    this.setState({ collageInfo: { data: newData } });
  }
  setIsOpenScene(newValue) {
    this.setState({ isOpenScene: newValue });
  }
  setIsOpenCollageAndEstimates(newValue) {
    this.setState({ isOpenCollageAndEstimates: newValue });
  }
  setIsOpenCollage(newValue) {
    this.setState({ isOpenCollage: newValue });
  }
  setIsOpenEvaluates(newValue) {
    this.setState({ isOpenEvaluates: newValue });
  }

  getChildContext() {
    return {
      ...objectsMap(actions, (actionNamespace) => this.props[actionNamespace]),
      translator: this.props.translator,
      catalog: this.props.catalog,
    };
  }

  componentWillMount() {
    let { store } = this.context;
    let { projectActions, catalog, stateExtractor, plugins } = this.props;
    plugins.forEach((plugin) => plugin(store, stateExtractor));

    projectActions.initCatalog(catalog);
  }

  componentWillReceiveProps(nextProps) {
    let { stateExtractor, state, projectActions, catalog } = nextProps;
    let plannerState = stateExtractor(state);
    let catalogReady = plannerState.getIn(["catalog", "ready"]);

    if (!catalogReady) {
      projectActions.initCatalog(catalog);
    }
  }

  render() {
    // console.log(this.state.isPrinting);
    let { width, height, state, stateExtractor, ...props } = this.props;

    let contentW = width - toolbarW - sidebarW;
    let toolbarH = height - footerBarH;
    let contentH = height - footerBarH;
    let sidebarH = height - footerBarH;

    let extractedState = stateExtractor(state);

    // console.log(extractedState)
    // console.log(this.props.state)
    // console.log(extractedState)
    // console.log(this.props.state.isLoading)

    return (
      <div style={{ ...containerStyle }}>
        <Meta
          state={extractedState}
          {...props}
          setCollageInfo={this.setCollageInfo}
          setIsOpenScene={this.setIsOpenScene}
          setIsOpenCollageAndEstimates={this.setIsOpenCollageAndEstimates}
          setIsOpenCollage={this.setIsOpenCollage}
          setIsOpenEvaluates={this.setIsOpenEvaluates}
          isOpenScene={this.state.isOpenScene}
          isOpenCollageAndEstimates={this.state.isOpenCollageAndEstimates}
          isOpenCollage={this.state.isOpenCollage}
          isOpenEvaluates={this.state.isOpenEvaluates}
          collageInfo={this.state.collageInfo}
          setIsPrinting={this.setIsPrinting}
        />
        {/* <MainMenu /> */}
        {this.state.collageInfo.data && this.state.isOpenEvaluates && (
          <Estimates
            handleEditFurniture={() => {}}
            estimatesData={this.state.collageInfo.data.furniture}
            isCollage={false}
            setChangingFurnitureId={(b) => {}}
            setCollageInfo={this.setCollageInfo}
            collageInfo={this.state.collageInfo}
          />
        )}

        {this.state.isOpenCollage && (
          <Collage
            // isFullWindow={true}
            // setChangingFurnitureId={setChangingFurnitureId}
            // isLoadingCollage={isLoadingCollage}
            // setIsLoadingCollage={setIsLoadingCollage}
            isFullWindow={true}
            setChangingFurnitureId={() => {}}
            isLoadingCollage={false}
            setIsLoadingCollage={() => {}}
            setCollageInfo={this.setCollageInfo}
            collageInfo={this.state.collageInfo}
          />
        )}

        {!extractedState.isLoading && this.state.isOpenScene && (
          <div style={{ ...wrapperStyle, height }}>
            <Toolbar
              width={toolbarW}
              height={toolbarH}
              state={extractedState}
              {...props}
            />
            <Content
              width={contentW}
              height={contentH}
              state={extractedState}
              {...props}
              onWheel={(event) => event.preventDefault()}
            />
            {/* <Sidebar width={sidebarW} height={sidebarH} state={extractedState} {...props} /> */}
            {/* <FooterBar width={width} height={footerBarH} state={extractedState} {...props} /> */}
          </div>
        )}
        {extractedState.isLoading && (
          <div style={overlayStyle}>
            <div>
              <LoaderIcon />
            </div>
          </div>
        )}
        {this.state.isPrinting && (
          <PrintedComponent
            isPrinting={this.state.isPrinting}
            setIsPrinting={this.setIsPrinting}
            //   ref={refPrintedComponent}
            estimatesData={this.state.collageInfo.data.furniture}
            // setLocalRooms={setLocalRooms}
            // zoom={zoom}
            // localRooms={localRooms}
            // isBuildMode={isBuildMode}
            // selectedWallThickness={selectedWallThickness}
            // selectedFurniture={selectedFurniture}
            // roomTypes={roomTypes}
            // clearSelectedItem={clearSelectedItem}
            // handleEditFurniture={() => {}} //{handleEditFurniture}
            // selectedFurnitures={selectedFurnitures}
            // collageSrc={collageSrc}
            // isOpenCollageAndEstimates={isOpenCollageAndEstimates}
            // isOpenCollage={isOpenCollage}
            // handleSyncCollage={() => {}} //{handleSyncCollage}
            // selectedWindowOnWall={selectedWindowOnWall}
            // selectedDoorOnWall={selectedDoorOnWall}
            setIsLoadingCollage={(boolean) => {}}
            isLoadingCollage={false}
            setCollageInfo={this.setCollageInfo}
            collageInfo={this.state.collageInfo}
          />
        )}
      </div>
    );
  }
}

ReactPlanner.propTypes = {
  translator: PropTypes.instanceOf(Translator),
  catalog: PropTypes.instanceOf(Catalog),
  allowProjectFileSupport: PropTypes.bool,
  plugins: PropTypes.arrayOf(PropTypes.func),
  autosaveKey: PropTypes.string,
  autosaveDelay: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  stateExtractor: PropTypes.func.isRequired,
  toolbarButtons: PropTypes.array,
  sidebarComponents: PropTypes.array,
  footerbarComponents: PropTypes.array,
  customContents: PropTypes.object,
  softwareSignature: PropTypes.string,
};

ReactPlanner.contextTypes = {
  store: PropTypes.object.isRequired,
};

ReactPlanner.childContextTypes = {
  ...objectsMap(actions, () => PropTypes.object),
  translator: PropTypes.object,
  catalog: PropTypes.object,
};

ReactPlanner.defaultProps = {
  translator: new Translator(),
  catalog: new Catalog(),
  plugins: [],
  allowProjectFileSupport: true,
  softwareSignature: `React-Planner ${VERSION}`,
  toolbarButtons: [],
  sidebarComponents: [],
  footerbarComponents: [],
  customContents: {},
};

//redux connect
function mapStateToProps(reduxState) {
  return {
    state: reduxState,
  };
}

function mapDispatchToProps(dispatch) {
  return objectsMap(actions, (actionNamespace) =>
    bindActionCreators(actions[actionNamespace], dispatch)
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactPlanner);
