var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n"])));
export var ModalContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-height: 90vh;\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  // max-width: 500px;\n  width: 100%;\n  position: relative;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);\n  overflow-y: auto;\n\n  ", "\n"], ["\n  max-height: 90vh;\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  // max-width: 500px;\n  width: 100%;\n  position: relative;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);\n  overflow-y: auto;\n\n  ", "\n"])), function (props) {
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      max-width: ", "px;\n\n    "], ["\n      max-width: ", "px;\n\n    "])), props.$maxWidth);
});
var templateObject_1, templateObject_2, templateObject_3;
