import { fetchPutRequests } from "src/api/fetch/put";
import { projectsUrl } from "src/api/urls/get-projects";
export var updateProjectByIdRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data, id = _a.id;
    fetchPutRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: projectsUrl,
        data: data,
        urlId: "/".concat(id),
    });
};
