var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var tablePadding = "12px";
var rightBorder = "border-right: solid 1px;";
var heightItem = "50px";
var greenColor = "rgba(141, 198, 70, 0.2)";
export var EstimatesStyledContend = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  padding-top: 0;\n  // margin-right: 120px;\n  // page-break-before: always;\n  @media print {\n    background-color: #ffffff;\n    margin-top: 20px;\n    @page {\n      size: portrait;\n      margin: 40px !important;\n      //  page-break-before: always;\n    }\n  }\n"], ["\n  padding: 20px;\n  padding-top: 0;\n  // margin-right: 120px;\n  // page-break-before: always;\n  @media print {\n    background-color: #ffffff;\n    margin-top: 20px;\n    @page {\n      size: portrait;\n      margin: 40px !important;\n      //  page-break-before: always;\n    }\n  }\n"])));
export var TableTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  background-color: rgba(141, 198, 70, 1);\n  padding: 14px;\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  position: sticky;\n  top: 0;\n  z-index: 10;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  background-color: rgba(141, 198, 70, 1);\n  padding: 14px;\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  position: sticky;\n  top: 0;\n  z-index: 10;\n"])));
export var TableHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  padding: ", ";\n  position: sticky;\n  top: 40px;\n  z-index: 10;\n  background-color: #fff;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  padding: ", ";\n  position: sticky;\n  top: 40px;\n  z-index: 10;\n  background-color: #fff;\n  justify-content: space-between;\n"])), tablePadding);
export var TableHeaderItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: ", ";\n  ", "\n  height: 30px;\n  align-items: center;\n  padding-left: 12px;\n  padding-right: 12px;\n  justify-content: center;\n  display: flex;\n  &:last-child {\n    border-right: none;\n  }\n"], ["\n  width: ", ";\n  ", "\n  height: 30px;\n  align-items: center;\n  padding-left: 12px;\n  padding-right: 12px;\n  justify-content: center;\n  display: flex;\n  &:last-child {\n    border-right: none;\n  }\n"])), function (_a) {
    var $width = _a.$width;
    return ($width ? "".concat($width, "px") : "calc(100% / 7)");
}, rightBorder);
export var TableBody = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  // padding: 10px;\n  //   display: flex;\n\n  //   @media (max-width: 780px) {\n  //     width: 100%;\n  //     box-shadow: none;\n  //   }\n"], ["\n  // padding: 10px;\n  //   display: flex;\n\n  //   @media (max-width: 780px) {\n  //     width: 100%;\n  //     box-shadow: none;\n  //   }\n"])));
export var TableBodyItems = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  padding: ", ";\n  align-items: center;\n  justify-content: space-between;\n\n  &:nth-child(odd) {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  padding: ", ";\n  align-items: center;\n  justify-content: space-between;\n\n  &:nth-child(odd) {\n    background-color: ", ";\n  }\n"])), tablePadding, greenColor);
export var TableBodyItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: ", ";\n  ", "\n  min-height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  // padding: 14px;\n  padding-left: 12px;\n  padding-right: 12px;\n  box-sizing: border-box;\n  text-align: center;\n  &:last-child {\n    border-right: none;\n  }\n"], ["\n  width: ", ";\n  ", "\n  min-height: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  // padding: 14px;\n  padding-left: 12px;\n  padding-right: 12px;\n  box-sizing: border-box;\n  text-align: center;\n  &:last-child {\n    border-right: none;\n  }\n"])), function (_a) {
    var $width = _a.$width, $isCollage = _a.$isCollage;
    return $width ? "".concat($width, "px") : "calc(100% / 7)";
}, rightBorder, heightItem);
export var TableBodyItemQuantity = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background-color: white;\n  padding: 4px 8px;\n  border-radius: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background-color: white;\n  padding: 4px 8px;\n  border-radius: 12px;\n"])));
export var TableBodyItemRemove = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"])));
export var TableFooter = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  //   width: calc(100% / 6);\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  gap: 28px;\n  background-color: white;\n  padding: 20px;\n\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n"], ["\n  //   width: calc(100% / 6);\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  gap: 28px;\n  background-color: white;\n  padding: 20px;\n\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n"])));
export var TableBodyItemImg = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  background-color: white;\n  padding: 10px;\n  border-radius: 10px;\n\n  > img {\n    width: 100%;\n    object-fit: contain;\n    height: 100%;\n  }\n"], ["\n  width: ", ";\n  height: ", ";\n  background-color: white;\n  padding: 10px;\n  border-radius: 10px;\n\n  > img {\n    width: 100%;\n    object-fit: contain;\n    height: 100%;\n  }\n"])), heightItem, heightItem);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
