var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useMemo, useState } from "react";
import { SelectBox } from "src/ui-kit/select-box";
import { localStorageWorker } from "src/utils/local-storage-worker";
import { LOCAL_STRORAGE_KEYS } from "src/constants";
import { Modal } from "src/ui-kit/modal";
import { InputCustom } from "src/ui-kit/Input";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { ModalContent, ButtonContainer } from "./styled";
var addProjectButtonValue = "addButton";
export var Projects = memo(function Projects(_a) {
    var projectsData = _a.projectsData, handleRefresh = _a.handleRefresh, handleAddProject = _a.handleAddProject;
    var _b = __read(useState(false), 2), isOpenAddModal = _b[0], setIsOpenAddModal = _b[1];
    var _c = __read(useState(""), 2), newPtojectName = _c[0], setNewPtojectName = _c[1];
    var _d = __read(useState(localStorageWorker.get({
        key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_NAME,
    }) || ''), 2), selectedValue = _d[0], setSelectedValue = _d[1];
    var handleChangeInputValue = function (value) {
        setNewPtojectName(value);
    };
    var handleCloseModal = useCallback(function () {
        handleClearNameProject();
        setIsOpenAddModal(false);
    }, []);
    var handleOpenModal = useCallback(function () {
        setIsOpenAddModal(true);
    }, []);
    var getSelectOptions = useMemo(function () {
        var projects = projectsData.map(function (example) {
            return {
                id: "".concat(example.id),
                label: example.name,
                value: "".concat(example.id),
            };
        });
        projects.push({
            id: addProjectButtonValue,
            label: "Добавить новый проект",
            value: addProjectButtonValue,
            isOnlyButton: true,
        });
        return projects;
    }, [projectsData]);
    var handleChangeSelecterdProject = function (value) {
        var projectId = value.id, projectName = value.label;
        if (projectId === addProjectButtonValue) {
            handleOpenModal();
            return;
        }
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_ID,
            value: projectId,
        });
        localStorageWorker.set({
            key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_NAME,
            value: projectName,
        });
        handleRefresh();
    };
    var handleClearNameProject = function () {
        setNewPtojectName("");
    };
    var handleOnAddClick = function () {
        var callbackOnSuccess = function () {
            handleCloseModal();
            handleRefresh();
            localStorageWorker.set({
                key: LOCAL_STRORAGE_KEYS.SELECTED_PROJECT_NAME,
                value: newPtojectName,
            });
            setSelectedValue(newPtojectName);
        };
        handleAddProject({
            name: newPtojectName,
            callbackOnSuccess: callbackOnSuccess,
        });
        handleClearNameProject();
    };
    return (React.createElement("div", { style: { display: "flex", alignItems: "center" } },
        React.createElement(CommonText, { text: "\u041F\u0440\u043E\u0435\u043A\u0442: " }),
        React.createElement(SelectBox, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0440\u043E\u0435\u043A\u0442", onValueChange: handleChangeSelecterdProject, options: getSelectOptions, selectedValue: selectedValue }),
        React.createElement(Modal, { isOpen: isOpenAddModal, onClose: handleCloseModal },
            React.createElement(ModalContent, null,
                React.createElement(CommonText, { text: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u043E\u0435\u043A\u0442\u0430" }),
                React.createElement(InputCustom, { value: newPtojectName, onChange: handleChangeInputValue }),
                React.createElement(ButtonContainer, null,
                    React.createElement(CommonButton, { isDisabled: !newPtojectName, onClick: handleOnAddClick },
                        React.createElement(CommonText, { text: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })),
                    React.createElement(CommonButton, { onClick: handleCloseModal },
                        React.createElement(CommonText, { text: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" })))))));
});
