export var CONFIG_LEFT_MENU = [
    {
        imageSrc: require("src/assets/img/Building.png"),
        type: "build",
        title: "Архитектура",
        transTitle: "buildText",
        disabled: false,
    },
    {
        imageSrc: require("src/assets/img/Furniture.png"),
        type: "furniture",
        title: "Мебель",
        transTitle: "furnitureText",
        // disabled: true,
    },
    {
        imageSrc: require("src/assets/img/Finishing.png"),
        type: "finishing",
        title: "Отделка",
        transTitle: "finishingText",
        disabled: true,
    },
    {
        imageSrc: require("src/assets/img/Settings.png"),
        type: "setting",
        title: "Настройки",
        transTitle: "settingText",
        disabled: true,
    },
];
