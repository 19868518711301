var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, memo, useCallback, useEffect } from "react";
import { MenuLeftContainer, MenuItem, StyledIcons } from "./styled";
import { CommonButton } from "src/ui-kit/button";
import { CommonText } from "src/ui-kit/text";
import { CONFIG_LEFT_MENU } from "./config";
export var MenuLeft = memo(function MenuLeft(_a) {
    var onItemSelect = _a.onItemSelect, activeMenuType = _a.activeMenuType;
    var _b = __read(useState(""), 2), activeItem = _b[0], setActiveItem = _b[1];
    useEffect(function () {
        setActiveItem(activeMenuType);
    }, [activeMenuType]);
    var handleMenuItemClick = useCallback(function (itemName) { return function () {
        setActiveItem(function (activedItem) {
            if (activedItem === itemName) {
                return "";
            }
            return itemName;
        });
        onItemSelect(itemName);
    }; }, [onItemSelect]);
    return (React.createElement(MenuLeftContainer, null, CONFIG_LEFT_MENU.map(function (menuElem) {
        return (React.createElement(CommonButton, { key: menuElem.type, onClick: handleMenuItemClick(menuElem.type), isDisabled: menuElem.disabled },
            React.createElement(MenuItem, { "$isActive": activeItem === menuElem.type },
                React.createElement(StyledIcons, { src: menuElem.imageSrc, alt: menuElem.title }),
                React.createElement(CommonText, { text: menuElem.title }))));
    })));
});
