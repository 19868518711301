import { fetchPutRequests } from "src/api/fetch/put";
import { projectsUrl } from "src/api/urls/get-projects";
import { getProjectInfo } from '../../fetch/get-project-info';
export var postRoomsRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c, data = _a.data;
    var _d = getProjectInfo(), projectId = _d.projectId, layoutId = _d.layoutId;
    fetchPutRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        url: "".concat(projectsUrl, "/").concat(projectId, "/layouts/").concat(layoutId),
        data: { data: data },
    });
};
