import React, { memo, useCallback } from "react";
import { ThirdIconGroupContainer, IconWrapper, GreenBar, FirstGreenIcon, Text2D, SecondIconGroupContainer, MainHeaderContainer, Text, VerticalBar, HorizontalBar, DarkGreenIcon, IconGroup, FirstGroupIcon, Icon, MainHeaderIconStyled, IconGroupContainer, } from "./styled";
import { IconThree } from "./icon";
import { CommonButton } from "src/ui-kit/button";
import * as constants from 'src/constants';
export var MainHeaderIcon = memo(function MainHeaderIcon(_a) {
    var handlePlusZoomScene = _a.handlePlusZoomScene, handleMinusZoomScene = _a.handleMinusZoomScene, handleNextActionButton = _a.handleNextActionButton, handleBackActionButton = _a.handleBackActionButton, handleOpenEvaluates = _a.handleOpenEvaluates, handleOpenScene = _a.handleOpenScene, handleOpenCollageAndEstimates = _a.handleOpenCollageAndEstimates, handleOpenCollage = _a.handleOpenCollage, onLoadEstimates = _a.onLoadEstimates, handlePrint = _a.handlePrint, isOpenCollageAndEstimates = _a.isOpenCollageAndEstimates, isOpenCollage = _a.isOpenCollage, isOpenScene = _a.isOpenScene, isOpenEvaluates = _a.isOpenEvaluates, state = _a.state, viewer3DActions = _a.viewer3DActions, viewer2DActions = _a.viewer2DActions, projectActions = _a.projectActions, handleGenerateProjectFromBuild = _a.handleGenerateProjectFromBuild;
    var mode = state.get('mode');
    var isDisplay3D = mode === constants.MODE_3D_VIEW;
    var isDisplay2d = mode === constants.MODE_IDLE ||
        mode === constants.MODE_2D_ZOOM_IN ||
        mode === constants.MODE_2D_ZOOM_OUT ||
        mode === constants.MODE_2D_PAN ||
        mode === constants.MODE_WAITING_DRAWING_LINE ||
        mode === constants.MODE_DRAGGING_LINE ||
        mode === constants.MODE_DRAGGING_VERTEX ||
        mode === constants.MODE_DRAGGING_ITEM ||
        mode === constants.MODE_DRAWING_LINE ||
        mode === constants.MODE_DRAWING_HOLE ||
        mode === constants.MODE_DRAWING_ITEM ||
        mode === constants.MODE_DRAGGING_HOLE ||
        mode === constants.MODE_ROTATING_ITEM;
    var handleMockFn = useCallback(function () { }, []);
    var onOpenEstimates = useCallback(function () {
        handleOpenEvaluates();
        onLoadEstimates();
    }, [handleOpenEvaluates, onLoadEstimates]);
    var onOpenCollageAndEstimates = useCallback(function () {
        handleOpenCollageAndEstimates();
        onLoadEstimates();
    }, [handleOpenCollageAndEstimates, onLoadEstimates]);
    var onOpenCollage = useCallback(function () {
        handleOpenCollage();
    }, [handleOpenCollage]);
    var onPressPrint = function () {
        onLoadEstimates();
        handleOpenScene();
        handlePrint();
    };
    var handleOpen3D = function () {
        viewer3DActions.selectTool3DView();
    };
    var handleOpen2D = function () {
        // viewer2DActions.selectTool2DView()
        projectActions.setMode(constants.MODE_IDLE);
    };
    // if (isDisplay3D) {
    //   return null
    // }
    return (React.createElement(MainHeaderIconStyled, null,
        !isDisplay3D && React.createElement(IconGroupContainer, null,
            React.createElement(CommonButton, { onClick: handleBackActionButton || handleNextActionButton, isDisabled: true },
                React.createElement(IconGroup, { src: require("src/assets/img/4icons.png"), alt: "Group Icons" })),
            React.createElement(CommonButton, { onClick: handlePlusZoomScene },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/+.png"), alt: "Plus Icon" })),
            React.createElement(CommonButton, { onClick: handleMinusZoomScene },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/-.png"), alt: "Minus Icon" })),
            React.createElement(CommonButton, { onClick: handleMockFn, isDisabled: true },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/cell.png"), alt: "Cell Icon" })),
            React.createElement(CommonButton, { onClick: handleMockFn, isDisabled: true },
                React.createElement(DarkGreenIcon, null,
                    React.createElement(VerticalBar, { style: { left: "5px", top: "14px" } }),
                    React.createElement(HorizontalBar, null),
                    React.createElement(VerticalBar, { style: { right: "5px", top: "14px" } }),
                    React.createElement(Text, null, "40\u043C\u043C"))),
            React.createElement(CommonButton, { onClick: handleMockFn, isDisabled: true },
                React.createElement(FirstGroupIcon, { src: require("src/assets/img/settingGreen.png"), alt: "Setting Icon" }))),
        React.createElement(MainHeaderContainer, null,
            React.createElement(SecondIconGroupContainer, null,
                React.createElement(CommonButton, { onClick: handleOpen2D },
                    React.createElement(IconWrapper, { isSelected: isDisplay2d },
                        React.createElement(FirstGreenIcon, null,
                            React.createElement(Text2D, null, "2D")))),
                React.createElement(CommonButton, { onClick: handleOpen3D },
                    React.createElement(IconWrapper, { isSelected: isDisplay3D },
                        React.createElement(FirstGreenIcon, null,
                            React.createElement(Text2D, null, "3D")))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: handleGenerateProjectFromBuild },
                    React.createElement(IconWrapper, null,
                        React.createElement(IconThree, null))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: onOpenEstimates },
                    React.createElement(IconWrapper, { isSelected: isOpenEvaluates },
                        React.createElement(Icon, { src: require("src/assets/img/notes.png"), alt: "Notes" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: onOpenCollage },
                    React.createElement(IconWrapper, { isSelected: isOpenCollage },
                        React.createElement(Icon, { src: require("src/assets/img/groupFurniture.png"), alt: "Furniture" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: onOpenCollageAndEstimates },
                    React.createElement(IconWrapper, { isSelected: isOpenCollageAndEstimates },
                        React.createElement(Icon, { src: require("src/assets/img/window.png"), alt: "Window" })))),
            React.createElement(ThirdIconGroupContainer, null,
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: onPressPrint },
                    React.createElement(IconWrapper, { isSelected: false },
                        React.createElement(Icon, { src: require("src/assets/img/print.png"), alt: "Print" }))),
                React.createElement(GreenBar, null),
                React.createElement(CommonButton, { isDisabled: isDisplay3D, onClick: handleMockFn },
                    React.createElement(IconWrapper, null,
                        React.createElement(Icon, { src: require("src/assets/img/send.png"), alt: "Send" })))))));
});
