var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 30px;\n   box-sizing: border-box;\n  padding: 32px;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 30px;\n   box-sizing: border-box;\n  padding: 32px;\n"])));
export var ChoiseCreate = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n"], ["\n  display: flex;\n  gap: 20px;\n"])));
export var ChoiseCreateTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 24px;\n  justify-content: center;\n"], ["\n  display: flex;\n  margin-bottom: 24px;\n  justify-content: center;\n"])));
export var ChoiseCreateElement = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  cursor: pointer;\n  width: 440px;\n  height: 368px;\n    box-sizing: border-box;\n  padding: 32px;\n  border: 1px #fff solid;\n  flex-direction: column;\n  align-items: center;\n\n  ", "\n"], ["\n  display: flex;\n  cursor: pointer;\n  width: 440px;\n  height: 368px;\n    box-sizing: border-box;\n  padding: 32px;\n  border: 1px #fff solid;\n  flex-direction: column;\n  align-items: center;\n\n  ", "\n"])), function (props) {
    return props.$isSelected && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      border: 1px #8dc646 solid;\n      border-radius: 8px;\n    "], ["\n      border: 1px #8dc646 solid;\n      border-radius: 8px;\n    "])));
});
export var ChoiseCreateDescription = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var ChoiseCreateDescriptionTitle = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin: 20px;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin: 20px;\n"])));
export var ChoiseCreateDescriptionText = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var NextButton = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  background-color: #8dc646;\n  padding: 12px 32px;\n  border-radius: 10px;\n"], ["\n  display: flex;\n  background-color: #8dc646;\n  padding: 12px 32px;\n  border-radius: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
