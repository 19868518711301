import { fetchPostRequests } from "src/api/fetch/post";
import { doorsUrl } from "src/api/urls/get-doors";
import { responseDataFormatter } from "./response-formatter";
export var getDoorsRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    fetchPostRequests({
        onSuccess: onSuccess,
        onError: onError,
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        responseDataFormatter: responseDataFormatter,
        url: doorsUrl,
        data: [],
        /** ONLY_FOR_DEVELOP */
        // mockData: MOCK_SHARDS,
    });
};
