var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from "styled-components";
var hoverBrightness = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    filter: brightness(1);\n  }\n  100% {\n    filter: brightness(1.1);\n  }\n"], ["\n  0% {\n    filter: brightness(1);\n  }\n  100% {\n    filter: brightness(1.1);\n  }\n"])));
export var ButtonWrapper = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  border: none;\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  max-width: 100%;\n  display: block;\n  ", "\n\n  width: ", ";\n\n\n  ", "\n"], ["\n  cursor: pointer;\n  border: none;\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  max-width: 100%;\n  display: block;\n  ", "\n\n  width: ", ";\n\n\n  ", "\n"])), function (props) {
    return props.disabled && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      opacity: 0.5;\n      pointer-events: none;\n    "], ["\n      opacity: 0.5;\n      pointer-events: none;\n    "])));
}, function (_a) {
    var $isFullWidth = _a.$isFullWidth;
    return ($isFullWidth ? "100%" : "auto");
}, function (_a) {
    var $isHoverAction = _a.$isHoverAction;
    return $isHoverAction && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover {\n    animation: ", " 0.3s ease-in-out forwards;\n  }\n"], ["\n  &:hover {\n    animation: ", " 0.3s ease-in-out forwards;\n  }\n"])), hoverBrightness);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
