import React, { memo } from "react";
import { IconWrapper } from "./styled";
export var IconThree = memo(function IconThree() {
    return (React.createElement(IconWrapper, null,
        React.createElement("div", { style: { position: "relative", width: "45px", height: "46px" } },
            React.createElement("svg", { style: { position: "absolute", top: "10px", left: "10px" }, xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
                React.createElement("g", { clipPath: "url(#clip0_793_39)" },
                    React.createElement("path", { d: "M17 3.03516H7.14286C6.23553 3.03516 5.5 3.77069 5.5 4.67801V12.8923C5.5 13.7996 6.23553 14.5352 7.14286 14.5352H17C17.9073 14.5352 18.6429 13.7996 18.6429 12.8923V4.67801C18.6429 3.77069 17.9073 3.03516 17 3.03516Z", stroke: "#8DC646", strokeWidth: "2.14286", strokeLinecap: "round", strokeLinejoin: "round" }),
                    React.createElement("path", { d: "M5.49972 7.96387H3.03544C2.59972 7.96387 2.18186 8.13695 1.87376 8.44505C1.56566 8.75314 1.39258 9.17101 1.39258 9.60672V16.9996C1.39258 17.4353 1.56566 17.8532 1.87376 18.1613C2.18186 18.4694 2.59972 18.6424 3.03544 18.6424H21.1069C21.5426 18.6424 21.9604 18.4694 22.2685 18.1613C22.5766 17.8532 22.7497 17.4353 22.7497 16.9996V9.60672C22.7497 9.17101 22.5766 8.75314 22.2685 8.44505C21.9604 8.13695 21.5426 7.96387 21.1069 7.96387H18.6426M7.14258 18.6424L6.32115 22.7496M16.9997 18.6424L17.8211 22.7496", stroke: "#8DC646", strokeWidth: "2.14286", strokeLinecap: "round", strokeLinejoin: "round" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0_793_39" },
                        React.createElement("rect", { width: "23", height: "23", fill: "white", transform: "translate(0.571289 0.571289)" })))),
            React.createElement("svg", { style: { position: "absolute", top: "2px", right: "5px" }, xmlns: "http://www.w3.org/2000/svg", width: "13", height: "13", viewBox: "0 0 13 13", fill: "none" },
                React.createElement("g", { clipPath: "url(#clip0_793_43)" },
                    React.createElement("path", { d: "M10.0713 5.07129L10.6963 3.69629L12.0713 3.07129L10.6963 2.44629L10.0713 1.07129L9.44629 2.44629L8.07129 3.07129L9.44629 3.69629L10.0713 5.07129ZM10.0713 8.07129L9.44629 9.44629L8.07129 10.0713L9.44629 10.6963L10.0713 12.0713L10.6963 10.6963L12.0713 10.0713L10.6963 9.44629L10.0713 8.07129ZM6.32129 5.32129L5.07129 2.57129L3.82129 5.32129L1.07129 6.57129L3.82129 7.82129L5.07129 10.5713L6.32129 7.82129L9.07129 6.57129L6.32129 5.32129ZM5.56629 7.06629L5.07129 8.15629L4.57629 7.06629L3.48629 6.57129L4.57629 6.07629L5.07129 4.98629L5.56629 6.07629L6.65629 6.57129L5.56629 7.06629Z", fill: "#8DC646" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0_793_43" },
                        React.createElement("rect", { width: "12", height: "12", fill: "white", transform: "translate(0.571289 0.571289)" })))),
            React.createElement("svg", { style: { position: "absolute", top: "25px", left: "0" }, xmlns: "http://www.w3.org/2000/svg", width: "11", height: "11", viewBox: "0 0 11 11", fill: "none" },
                React.createElement("g", { clipPath: "url(#clip0_793_45)" },
                    React.createElement("path", { d: "M8.48828 4.32161L9.00912 3.17578L10.1549 2.65495L9.00912 2.13411L8.48828 0.988281L7.96745 2.13411L6.82161 2.65495L7.96745 3.17578L8.48828 4.32161ZM8.48828 6.82161L7.96745 7.96745L6.82161 8.48828L7.96745 9.00912L8.48828 10.1549L9.00912 9.00912L10.1549 8.48828L9.00912 7.96745L8.48828 6.82161ZM5.36328 4.52995L4.32161 2.23828L3.27995 4.52995L0.988281 5.57161L3.27995 6.61328L4.32161 8.90495L5.36328 6.61328L7.65495 5.57161L5.36328 4.52995ZM4.73411 5.98411L4.32161 6.89245L3.90911 5.98411L3.00078 5.57161L3.90911 5.15911L4.32161 4.25078L4.73411 5.15911L5.64245 5.57161L4.73411 5.98411Z", fill: "#8DC646" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0_793_45" },
                        React.createElement("rect", { width: "10", height: "10", fill: "white", transform: "translate(0.571289 0.571289)" })))))));
});
