import React, { useRef } from 'react';
import { ModalWrapper, ModalContent } from './styled';
export var Modal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, children = _a.children, _b = _a.maxWidth, maxWidth = _b === void 0 ? 500 : _b;
    var modalRef = useRef(null);
    var handleClickOutside = function (event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };
    if (!isOpen)
        return null;
    return (React.createElement(ModalWrapper, { onClick: handleClickOutside },
        React.createElement(ModalContent, { "$maxWidth": maxWidth, ref: modalRef }, children)));
};
