// @ts-nocheck
class CollageComponent {
  // _dom = null;
  // _ctx = null;
  // _parent = null;
  // _id = null;
  // _type = null;
  // _alike = null;
  // _layer = null;
  // _width = null;
  // _height = null;
  // _ratioNatural = 1; //Соотношение
  // _x = 0;
  // _y = 0;
  // _ratioWidth = null; //Соотношение реального размера в мм к пикселям на экране
  // _ratioHeight = 1; //
  // _widthNatural = null;
  // _heightNatural = null;
  // _position = null;
  // _parentPosition = null;
  // _options = null;
  // _optionsDefault = {
  //   width: 100,
  //   height: 0,
  //   position: null,
  //   name: "",
  //   count: 0,
  //   image: "",
  //   price: 0,
  // };
  // _name = "";
  // _count = "";
  // _image = "";
  // _price = "";
  // _rotation = "front";

  constructor(
    ctx,
    options = {},
    parent = null,
    isPrint = false,
    printedCollageWidth = 0
  ) {
    this._dom = null;
    this._ctx = null;
    this._parent = null;
    this._id = null;
    this._type = null;
    this._alike = null;
    this._layer = null;
    this._width = null;
    this._height = null;
    this._ratioNatural = 1; //Соотношение
    this._x = 0;
    this._y = 0;
    this._ratioWidth = null; //Соотношение реального размера в мм к пикселям на экране
    this._ratioHeight = 1; //
    this._widthNatural = null;
    this._heightNatural = null;
    this._position = null;
    this._parentPosition = null;
    this._options = null;
    this._optionsDefault = {
      width: 100,
      height: 0,
      position: null,
      name: "",
      count: 0,
      image: "",
      price: 0,
    };
    this._name = "";
    this._count = "";
    this._image = "";
    this._price = "";
    this._rotation = "front";

    this._ctx = ctx;
    this._parent = parent;
    this.updateOptions(options);
    this._isPrint = isPrint;
    this._printedCollageWidth = printedCollageWidth;
  }

  updateOptions(options) {
    this._options = Object.assign(this._optionsDefault, options);
    this._id =
      (this._options.id === null || this._options.id === undefined) && this._id;
    this._type =
      (this._options.type === null || this._options.type === undefined) &&
      this._type;
    this._alike =
      (this._options.alike === null || this._options.alike === undefined) &&
      this._alike;
    this._width =
      (this._options.width === null || this._options.width === undefined) &&
      this._width;
    this._name =
      (this._options.name === null || this._options.name === undefined) &&
      this._name;
    this._count = this._options.count;
    this._image = this._options.image;
    this._price = this._options.price;
    this._layer = this._options.layer;
    this._position = this._options.position;
    this._parentPosition = this._options.parentPosition;
    this._x = this._options.x;
    this._y = this._options.y;
    this._rotation = this._options.rotation;
  }
  // async init() {
  //   this._dom = await this.createElement(this._options);
  // }
  init() {
    return new Promise((resolve, reject) => {
      let newDom = this.createElement(this._options);
      resolve(newDom);
    }).then((newDom) => {
      this._dom = newDom;
      return;
    });
  }

  get id() {
    return this._id;
  }
  get type() {
    return this._type;
  }
  get alike() {
    return this._alike;
  }
  get name() {
    return this._name;
  }
  get count() {
    return this._count;
  }
  get image() {
    return this._image;
  }
  get price() {
    return this._price;
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
  get position() {
    return this._position;
  }
  get parentPosition() {
    return this._parentPosition;
  }
  get layer() {
    return this._layer;
  }

  get ratioWidth() {
    if (this._ratioWidth) {
      return this._ratioWidth;
    }
    if (this._parent && this._parent.ratioWidth) {
      return this._parent.ratioWidth;
    }
    return 1;
  }

  createElement(options) {
    return null;
  }

  getWidthAndUpdateRatio(naturalWidth = null) {
    let widthValue = this._options.width.toString();

    let percent = null;
    if (widthValue.substring(widthValue.length - 1) == "%") {
      percent = parseInt(widthValue.substring(0, widthValue.length - 1));
    }

    let fullWidthParent = null;
    if (this._parent && this._parent._dom && this._parent._dom.clientWidth) {
      fullWidthParent = this._parent._dom.clientWidth;
    }

    if (percent && fullWidthParent) {
      let widthResult = (fullWidthParent * percent) / 100;
      this._ratioWidth = naturalWidth ? widthResult / naturalWidth : 1;
      return widthResult;
    }

    if (this._parent && this._parent.ratioWidth) {
      this._ratioNatural = naturalWidth
        ? (this._options.width * this._parent.ratioWidth) / naturalWidth
        : 1;
      //this._ratioWidth = this._parent.ratioWidth;
      return parseInt(this._options.width) * this.ratioWidth;
    }

    return parseInt(this._options.width);
  }

  getHeightAndUpdateRatio(naturalHeight = null) {
    let heightValue = this._options.height.toString();

    let percent = null;
    if (heightValue.substring(heightValue.length - 1) == "%") {
      percent = parseInt(heightValue.substring(0, heightValue.length - 1));
    }

    let fullHeightParent = null;
    if (this._parent && this._parent._dom && this._parent._dom.clientHeight) {
      fullHeightParent = this._parent._dom.clientHeight;
    }

    if (percent && fullHeightParent) {
      let heightResult = (fullHeightParent * percent) / 100;
      this._ratioHeight = naturalHeight ? heightResult / naturalHeight : 1;
      return heightResult;
    }

    if (this._parent && this._parent._ratioHeight) {
      if (this._options.height > 0) {
        heightValue = this._options.height;
      } else if (naturalHeight) {
        heightValue = naturalHeight * this._ratioNatural;
      }
      this._ratioHeight = this._parent._ratioHeight;
      return parseInt(heightValue) * this._ratioHeight;
    }

    return parseInt(this._options.height);
  }

  set x(value) {
    this._x = value;
  }

  get x() {
    return this._x;
  }

  set y(value) {
    this._y = value;
  }

  get y() {
    return this._y;
  }

  getX() {
    let leftValue = this._options.left ? this._options.left.toString() : "0";

    let percent = null;
    if (leftValue.substring(leftValue.length - 1) == "%") {
      percent = parseInt(leftValue.substring(0, leftValue.length - 1));
    }

    let fullWidthParent = null;
    if (this._parent && this._parent.width) {
      fullWidthParent = this._parent.width;
    }

    if (percent && fullWidthParent) {
      return (fullWidthParent * percent) / 100;
    }

    return parseInt(this._options.position.left);
  }

  getY() {
    let y = this._options.y ? this._options.y.toString() : "0";

    let percent = null;
    if (y.substring(y.length - 1) == "%") {
      percent = parseInt(y.substring(0, y.length - 1));
    }

    let fullHeightParent = null;
    if (
      this._parent &&
      this._parent._dom &&
      (this._isPrint
        ? this._ctx && this._ctx.canvas && this._ctx.canvas.height
        : this._parent._dom.clientHeight)
    ) {
      fullHeightParent = this._isPrint
        ? this._ctx.canvas.height
        : this._parent._dom.clientHeight;
    }
    if (percent && fullHeightParent) {
      return (fullHeightParent * percent) / 100;
    }

    return parseInt(this._options.y);
  }
  toString() {
    return JSON.stringify({
      name: this._name,
      count: this._count,
      price: this._price,
      image: this._image,
    });
  }
}

export { CollageComponent };
