var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var transformObject = function (room) {
    var _a;
    var vertices = {};
    var lines = {};
    var holes = {};
    var transformArrayToObject = function (arr) {
        var result = {};
        arr === null || arr === void 0 ? void 0 : arr.forEach(function (item) {
            var position = item.position, rest = __rest(item, ["position"]);
            var x = position.x, y = position.y;
            result[item.id] = __assign(__assign({}, rest), { x: x, y: y });
        });
        return result;
    };
    var items = transformArrayToObject(room === null || room === void 0 ? void 0 : room.furniture);
    (_a = room === null || room === void 0 ? void 0 : room.walls) === null || _a === void 0 ? void 0 : _a.forEach(function (wall, index) {
        var startVertexId = "vertex-".concat(wall.coordinates.x0, "-").concat(wall.coordinates.y0);
        var endVertexId = "vertex-".concat(wall.coordinates.x1, "-").concat(wall.coordinates.y1);
        if (!vertices[startVertexId]) {
            vertices[startVertexId] = {
                id: startVertexId,
                type: "",
                prototype: "vertices",
                name: "Vertex",
                misc: {},
                selected: false,
                properties: {},
                visible: true,
                x: wall.coordinates.x0,
                y: wall.coordinates.y0,
                lines: [],
                areas: []
            };
        }
        if (!vertices[endVertexId]) {
            vertices[endVertexId] = {
                id: endVertexId,
                type: "",
                prototype: "vertices",
                name: "Vertex",
                misc: {},
                selected: false,
                properties: {},
                visible: true,
                x: wall.coordinates.x1,
                y: wall.coordinates.y1,
                lines: [],
                areas: []
            };
        }
        var lineId = "line-".concat(index);
        var holesArray = [];
        if (wall.entities.length) {
            wall.entities.forEach(function (entity) {
                var wallWithCorrectId = __assign(__assign({}, entity), { line: lineId });
                holes[entity.id] = wallWithCorrectId;
                holesArray.push(entity.id);
            });
            // console.log(wall)
        }
        lines[lineId] = {
            id: lineId,
            type: "wall",
            prototype: "lines",
            name: "Wall",
            misc: {},
            selected: false,
            properties: {
                height: {
                    length: wall.height
                },
                thickness: {
                    length: wall.thickness
                },
                textureA: "bricks",
                textureB: "bricks"
            },
            visible: true,
            vertices: [startVertexId, endVertexId],
            holes: holesArray,
        };
        vertices[startVertexId].lines.push(lineId);
        vertices[endVertexId].lines.push(lineId);
    });
    // console.log(holes)
    var layer = {
        id: "layer-1",
        altitude: 0,
        order: 0,
        opacity: 1,
        name: "default",
        visible: true,
        vertices: vertices,
        lines: lines,
        holes: holes,
        areas: {},
        items: items,
        selected: {
            vertices: [],
            lines: [],
            holes: [],
            areas: [],
            items: []
        }
    };
    // console.log(layer)
    var metaLayer = {
        unit: "cm",
        layers: {
            'layer-1': layer,
        },
        grids: {
            h1: {
                id: "h1",
                type: "horizontal-streak",
                properties: {
                    step: 20,
                    colors: [
                        "#808080",
                        "#ddd",
                        "#ddd",
                        "#ddd",
                        "#ddd"
                    ]
                }
            },
            v1: {
                id: "v1",
                type: "vertical-streak",
                properties: {
                    step: 20,
                    colors: [
                        "#808080",
                        "#ddd",
                        "#ddd",
                        "#ddd",
                        "#ddd"
                    ]
                }
            }
        },
        selectedLayer: "layer-1",
        groups: {},
        width: 3000,
        height: 2000,
        meta: {},
        guides: {
            horizontal: {},
            vertical: {},
            circular: {}
        }
    };
    console.log(metaLayer);
    return metaLayer;
};
// function convertData(data) {
//     const room = data[0];
//     const vertices = {};
//     const lines = {};
//     const holes = {};
//     const items = {};
//     // Проход по стенам
//     room.walls.forEach((wall, index) => {
//         // Вершины
//         const vertex1 = `vertex-${wall.coordinates.x0}-${wall.coordinates.y0}`;
//         const vertex2 = `vertex-${wall.coordinates.x1}-${wall.coordinates.y1}`;
//         if (!vertices[vertex1]) {
//             vertices[vertex1] = {
//                 id: vertex1,
//                 type: "",
//                 prototype: "vertices",
//                 name: "Vertex",
//                 misc: {},
//                 selected: false,
//                 properties: {},
//                 visible: true,
//                 x: wall.coordinates.x0,
//                 y: wall.coordinates.y0,
//                 lines: [],
//                 areas: []
//             };
//         }
//         if (!vertices[vertex2]) {
//             vertices[vertex2] = {
//                 id: vertex2,
//                 type: "",
//                 prototype: "vertices",
//                 name: "Vertex",
//                 misc: {},
//                 selected: false,
//                 properties: {},
//                 visible: true,
//                 x: wall.coordinates.x1,
//                 y: wall.coordinates.y1,
//                 lines: [],
//                 areas: []
//             };
//         }
//         // Линии
//         const lineId = `line-${index}`;
//         lines[lineId] = {
//             id: lineId,
//             type: "wall",
//             prototype: "lines",
//             name: "Wall",
//             misc: {},
//             selected: false,
//             properties: {
//                 height: { length: wall.height },
//                 thickness: { length: wall.thickness },
//                 textureA: "bricks",
//                 textureB: "bricks"
//             },
//             visible: true,
//             vertices: [vertex1, vertex2],
//             holes: wall.entities.map(entity => entity.id)
//         };
//         // Добавляем ссылку на линию в вершины
//         vertices[vertex1].lines.push(lineId);
//         vertices[vertex2].lines.push(lineId);
//         // Добавляем сущности (двери и окна) как отверстия (holes)
//         wall.entities.forEach(entity => {
//             holes[entity.id] = {
//                 id: entity.id,
//                 line: lineId,
//                 misc: {},
//                 name: entity.name,
//                 type: entity.type,
//                 offset: entity.offset,
//                 visible: entity.visible,
//                 selected: entity.selected,
//                 prototype: entity.prototype,
//                 properties: entity.properties
//             };
//         });
//     });
//     // Проход по мебели
//     room.furniture.forEach(item => {
//         items[item.id] = {
//             id: item.id,
//             misc: {},
//             name: item.name,
//             type: item.type,
//             visible: item.visible,
//             rotation: item.rotation,
//             selected: item.selected,
//             prototype: item.prototype,
//             properties: {},
//             x: item.position.x,
//             y: item.position.y
//         };
//     });
//     return {
//         unit: "cm",
//         layers: {
//             "layer-1": {
//                 id: "layer-1",
//                 altitude: 0,
//                 order: 0,
//                 opacity: 1,
//                 name: "default",
//                 visible: true,
//                 vertices,
//                 lines,
//                 holes,
//                 areas: {},
//                 items,
//                 selected: {
//                     vertices: [],
//                     lines: [],
//                     holes: [],
//                     areas: [],
//                     items: []
//                 }
//             }
//         },
//         grids: {
//             "h1": {
//                 id: "h1",
//                 type: "horizontal-streak",
//                 properties: {
//                     step: 20,
//                     colors: [
//                         "#808080",
//                         "#ddd",
//                         "#ddd",
//                         "#ddd",
//                         "#ddd"
//                     ]
//                 }
//             },
//             "v1": {
//                 id: "v1",
//                 type: "vertical-streak",
//                 properties: {
//                     step: 20,
//                     colors: [
//                         "#808080",
//                         "#ddd",
//                         "#ddd",
//                         "#ddd",
//                         "#ddd"
//                     ]
//                 }
//             }
//         },
//         selectedLayer: "layer-1",
//         groups: {},
//         width: 3000,
//         height: 2000,
//         meta: {},
//         guides: {
//             horizontal: {},
//             vertical: {},
//             circular: {}
//         }
//     };
// }
// // Пример использования функции
// const inputData = [
//     {
//         // входные данные
//     }
// ];
// const result = convertData(inputData);
// console.log(JSON.stringify(result, null, 2));
