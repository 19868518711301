import { fetchGetRequests } from "src/api/fetch/get";
import { responseDataFormatter } from "./response-formatter";
import { projectsUrl } from "src/api/urls/get-projects";
import { getProjectInfo } from '../../fetch/get-project-info';
export var getLayoutsRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    var projectId = getProjectInfo().projectId;
    fetchGetRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        responseDataFormatter: responseDataFormatter,
        url: projectsUrl,
        urlId: "/".concat(projectId, "/layouts"),
    });
};
