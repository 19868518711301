var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Z_INDEX } from "src/constants";
export var SelectBoxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  z-index: ", ";\n"], ["\n  position: relative;\n  width: 100%;\n  z-index: ", ";\n"])), Z_INDEX.turboMax);
export var SelectBoxStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 12px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  ", ";\n  &:hover {\n    background-color: #f0f0f0;\n  }\n"], ["\n  padding: 12px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  ", ";\n  &:hover {\n    background-color: #f0f0f0;\n  }\n"])), function (_a) {
    var $width = _a.$width;
    return ("width: ".concat($width));
});
export var DropdownList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  // max-height: 240px;\n  ", "\n  overflow-y: auto;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  margin-top: 4px;\n  padding: 0;\n  list-style: none;\n  z-index: 1;\n  ", "\n  left: 50%;\n  transform: translateX(-50%);\n  min-width: 100%;\n  max-height: 40vh;\n  overflow-x: auto;\n"], ["\n  position: absolute;\n  // max-height: 240px;\n  ", "\n  overflow-y: auto;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  margin-top: 4px;\n  padding: 0;\n  list-style: none;\n  z-index: 1;\n  ", "\n  left: 50%;\n  transform: translateX(-50%);\n  min-width: 100%;\n  max-height: 40vh;\n  overflow-x: auto;\n"])), function (_a) {
    var $maxHeight = _a.$maxHeight;
    return ("max-height: ".concat($maxHeight, "px"));
}, function (_a) {
    var $direction = _a.$direction;
    return ($direction === 'up' ? 'bottom: 100%; margin-bottom: 4px;' : 'top: 100%; margin-top: 4px;');
});
export var DropdownItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: max-content;\n  min-width: 100%;\n  padding: 12px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  &:hover {\n    background-color: #f0f0f0;\n  }\n  img {\n    margin-right: 8px;\n  }\n"], ["\n  width: max-content;\n  min-width: 100%;\n  padding: 12px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  &:hover {\n    background-color: #f0f0f0;\n  }\n  img {\n    margin-right: 8px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
