var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState } from "react";
import { Modal } from "src/ui-kit/modal";
import { CommonText } from "src/ui-kit/text";
import { CreateStart } from "./svg/createStart";
import { Choise } from "./svg/choise";
import { ChoiseCreate, ChoiseCreateElement, ChoiseCreateDescription, ChoiseCreateDescriptionTitle, ChoiseCreateDescriptionText, ChoiseCreateTitle, NextButton, Wrapper, } from "./styled";
import { BASE_GRAY, BASE_GREEN, BASE_BOLD, BASE_FONT_SIZE, BASE_WHITE, } from "src/ui-kit/colors";
import { CommonButton } from "src/ui-kit/button";
var CHOISES_IDS = {
    one: "1",
    two: "2",
    three: "3",
    four: "4",
};
export var ModalQuiz = memo(function ModalQuiz(_a) {
    var isOpenQuiz = _a.isOpenQuiz, onCloseQuiz = _a.onCloseQuiz;
    var _b = __read(useState(""), 2), selectedChoise = _b[0], setSelectedChoices = _b[1];
    var handleSelectChoice = function (id) { return function () {
        setSelectedChoices(id);
    }; };
    var handleIsSelectedElem = function (id) {
        return id === selectedChoise;
    };
    var handleNextButtonClick = function () {
    };
    return (React.createElement(Modal, { maxWidth: 1020, isOpen: isOpenQuiz, onClose: onCloseQuiz },
        React.createElement(Wrapper, null,
            React.createElement(ChoiseCreateTitle, null,
                React.createElement(CommonText, { fontSize: BASE_FONT_SIZE.title, fontWeight: BASE_BOLD, text: "\u041D\u0430\u0447\u0430\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u043F\u0440\u043E\u0435\u043A\u0442" })),
            React.createElement(ChoiseCreate, null,
                React.createElement(ChoiseCreateElement, { "$isSelected": handleIsSelectedElem(CHOISES_IDS.one), onClick: handleSelectChoice(CHOISES_IDS.one) },
                    React.createElement(CreateStart, null),
                    React.createElement(ChoiseCreateDescription, null,
                        React.createElement(ChoiseCreateDescriptionTitle, null,
                            React.createElement(CommonText, { fontSize: BASE_FONT_SIZE.smallTitle, fontWeight: BASE_BOLD, color: BASE_GREEN, text: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0441 \u043D\u0443\u043B\u044F" })),
                        React.createElement(ChoiseCreateDescriptionText, null,
                            React.createElement(CommonText, { fontSize: BASE_FONT_SIZE.baseDescription, color: BASE_GRAY, text: "\u041F\u0440\u043E\u044F\u0432\u0438 \u0442\u0432\u043E\u0440\u0447\u0435\u0441\u043A\u0438\u0439 \u043F\u043E\u0434\u0445\u043E\u0434 - \u0441\u043E\u0437\u0434\u0430\u0432\u0430\u0439 \u0438 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0443\u0439 \u043F\u043B\u0430\u043D \u0438 \u0434\u0438\u0437\u0430\u0439\u043D-\u043F\u0440\u043E\u0435\u043A\u0442 \u0441\u0432\u043E\u0435\u0439 \u043A\u043E\u043C\u043D\u0430\u0442\u044B" })))),
                React.createElement(ChoiseCreateElement, { "$isSelected": handleIsSelectedElem(CHOISES_IDS.two), onClick: handleSelectChoice(CHOISES_IDS.two) },
                    React.createElement(Choise, null),
                    React.createElement(ChoiseCreateDescription, null,
                        React.createElement(ChoiseCreateDescriptionTitle, null,
                            React.createElement(CommonText, { fontSize: BASE_FONT_SIZE.smallTitle, fontWeight: BASE_BOLD, color: BASE_GREEN, text: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C" })),
                        React.createElement(ChoiseCreateDescriptionText, null,
                            React.createElement(CommonText, { fontSize: BASE_FONT_SIZE.baseDescription, color: BASE_GRAY, text: "\u0412\u044B\u0431\u0435\u0440\u0438 \u0438\u0437 \u0442\u0438\u043F\u043E\u0432\u044B\u0445 \u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u043E\u043A \u043A\u0432\u0430\u0440\u0442\u0438\u0440\u0443 \u0438\u043B\u0438 \u043A\u043E\u043C\u043D\u0430\u0442\u0443, \u043F\u043E\u0445\u043E\u0436\u0443\u044E \u043D\u0430 \u0442\u0432\u043E\u044E, \u0438 \u0441\u043E\u0437\u0434\u0430\u0439 \u0441\u0432\u043E\u044E \u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u043A\u0443 \u0438 \u0434\u0438\u0437\u0430\u0439\u043D-\u043F\u0440\u043E\u0435\u043A\u0442" }))))),
            React.createElement(CommonButton, { onClick: handleNextButtonClick },
                React.createElement(NextButton, null,
                    React.createElement(CommonText, { color: BASE_WHITE, text: "\u0414\u0430\u043B\u0435\u0435" }))))));
});
