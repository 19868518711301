import { CollageComponent } from "./CollageComponent.js";

class CollageImageComponent extends CollageComponent {
  addImage(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => resolve(img);
      img.src = src;
    });
  }
  // async createElement(options) {
  //   if (options.image) {
  //     let imageSrc = options.image;
  //     if (options.rotation === "left" && options.image_left) {
  //       imageSrc = options.image_left;
  //     } else if (options.rotation === "right" && options.image_right) {
  //       imageSrc = options.image_right;
  //     }

  //     let img = await this.addImage(imageSrc);
  //     img.updateData = function () {
  //       this._widthNatural = img.naturalWidth;
  //       this._heightNatural = img.naturalHeight;
  //       this._width = this.getWidthAndUpdateRatio(img.naturalWidth);
  //       this._height = this.getHeightAndUpdateRatio(img.naturalHeight);
  //     }.bind(this);
  //     img.updateData();
  //     return img;
  //   }
  // }

  createElement(options) {
    if (options.image) {
      let imageSrc = options.image;
      if (options.rotation === "left" && options.image_left) {
        imageSrc = options.image_left;
      } else if (options.rotation === "right" && options.image_right) {
        imageSrc = options.image_right;
      }

      return this.addImage(imageSrc).then((img) => {
        img.updateData = function () {
          this._widthNatural = img.naturalWidth;
          this._heightNatural = img.naturalHeight;
          this._width = this.getWidthAndUpdateRatio(img.naturalWidth);
          this._height = this.getHeightAndUpdateRatio(img.naturalHeight);
        }.bind(this);
        img.updateData();
        return img;
      });
    }
    return;
  }

  render() {
    this._ctx.drawImage(this._dom, this._x, this._y, this._width, this._height);
  }
}

export { CollageImageComponent };
