import { getIdProject } from "src/api/middlewares/get-id-project";
import { getNameProject } from "src/api/middlewares/get-name-project";
import { getLayoutId } from "src/api/middlewares/get-layout-id";
import { getUserId } from "src/api/middlewares/get-user-id";
import { getLayoutName } from 'src/api/middlewares/get-layout-name';
export var getProjectInfo = function () {
    var projectName = getNameProject();
    var projectId = getIdProject();
    var layoutId = getLayoutId();
    var userId = getUserId();
    var layoutName = getLayoutName();
    return {
        projectName: projectName,
        projectId: projectId,
        layoutId: layoutId,
        userId: userId,
        layoutName: layoutName,
    };
};
