var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { CommonText } from "src/ui-kit/text";
import { CommonButton } from "src/ui-kit/button";
import { formatPriceSpaces } from "src/utils/common/format-price-spaces";
import { TableHeader, TableTitle, TableBody, TableBodyItem, TableHeaderItem, TableFooter, TableBodyItems, TableBodyItemImg, EstimatesStyledContend, TableBodyItemQuantity, TableBodyItemRemove, } from "./styled";
import { ITEMS_WIDTH } from "./config";
import { PlusSvg } from "../plusSvg";
import { MinusSvg } from "../minusSvg";
import { RemoveSvg } from "../removeSvg";
export var EstimatesTable = memo(function EstimatesTable(_a) {
    var estimatesData = _a.estimatesData, isCollage = _a.isCollage;
    var _b = __read(useState(estimatesData), 2), localEstimatesData = _b[0], setLocalEstimatesData = _b[1];
    var refForPrintEstimates = useRef();
    useEffect(function () {
        setLocalEstimatesData(estimatesData);
    }, [estimatesData]);
    console.log("estimatesData");
    console.log(estimatesData);
    var getCostText = useCallback(function (_a) {
        var quantity = _a.quantity, price = _a.price;
        var formattedPrice = "".concat(formatPriceSpaces(quantity * price), " \u20BD");
        return formattedPrice;
    }, []);
    var getTotalCostText = useMemo(function () {
        var totalCost = localEstimatesData.reduce(function (total, obj) {
            //return total + obj.quantity * obj.price;
            return total + 1 * obj.price;
        }, 0);
        var formattedTotalCost = "".concat(formatPriceSpaces(totalCost), " \u20BD");
        return formattedTotalCost;
    }, [localEstimatesData]);
    var handlePlusQuantity = useCallback(function (estimaty) { return function () {
        var updatedLocalEstimatesData = localEstimatesData.map(function (localEstimaty) {
            if (estimaty.id === localEstimaty.id) {
                return __assign(__assign({}, localEstimaty), { quantity: localEstimaty.quantity + 1 });
            }
            return localEstimaty;
        });
        setLocalEstimatesData(updatedLocalEstimatesData);
    }; }, [localEstimatesData]);
    var handleMinusQuantity = useCallback(function (estimaty) { return function () {
        var updatedLocalEstimatesData = localEstimatesData.map(function (localEstimaty) {
            if (estimaty.id === localEstimaty.id) {
                return __assign(__assign({}, localEstimaty), { quantity: localEstimaty.quantity - 1 });
            }
            return localEstimaty;
        });
        setLocalEstimatesData(updatedLocalEstimatesData);
    }; }, [localEstimatesData]);
    var getFillColor = useCallback(function (index) { return (index % 2 === 0 ? "white" : "#D1E8B5"); }, []);
    var handleRemoveEstimaty = useCallback(function (estimaty) { return function () {
        var fillteredLocalEstimatesData = localEstimatesData.filter(function (localEstimaty) { return localEstimaty.id !== estimaty.id; });
        setLocalEstimatesData(fillteredLocalEstimatesData);
    }; }, [localEstimatesData]);
    // console.log("localEstimatesData");
    // console.log(localEstimatesData);
    return (React.createElement(EstimatesStyledContend, { ref: refForPrintEstimates },
        React.createElement(TableTitle, null,
            React.createElement(CommonText, { fontWeight: "700", fontSize: "14px", text: "\u0421\u043C\u0435\u0442\u0430 \u043F\u043E\u043C\u0435\u0449\u0435\u043D\u0438\u044F" })),
        React.createElement(TableHeader, null,
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.firstElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u2116" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.secondElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u0424\u043E\u0442\u043E" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.thirdElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.thirdHalfElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.fourthElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u0426\u0435\u043D\u0430" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.fifthElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E" })),
            React.createElement(TableHeaderItem, { "$width": ITEMS_WIDTH.sixthElem, "$isCollage": isCollage },
                React.createElement(CommonText, { fontWeight: "700", text: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C" }))),
        React.createElement(TableBody, null, localEstimatesData.map(function (furniture, index) {
            return (React.createElement(TableBodyItems, { key: furniture.id },
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.firstElem, "$isCollage": isCollage },
                    React.createElement(CommonText, { text: "".concat(index + 1) })),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.secondElem, "$isCollage": isCollage },
                    React.createElement(TableBodyItemImg, null,
                        React.createElement("img", { src: "".concat(furniture.image) }))),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.thirdElem, "$isCollage": isCollage },
                    React.createElement(CommonText, { text: furniture.name })),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.thirdHalfElem, "$isCollage": isCollage },
                    React.createElement(CommonText, { text: furniture.description })),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.fourthElem, "$isCollage": isCollage },
                    React.createElement(CommonText, { text: "".concat(furniture.price) })),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.fifthElem, "$isCollage": isCollage },
                    React.createElement(TableBodyItemQuantity, null,
                        React.createElement(CommonButton, { isDisabled: furniture.quantity < 2, onClick: handleMinusQuantity(furniture) },
                            React.createElement(MinusSvg, null)),
                        React.createElement(CommonText, { text: "1" }),
                        React.createElement(CommonButton, { onClick: handlePlusQuantity(furniture) },
                            React.createElement(PlusSvg, null)))),
                React.createElement(TableBodyItem, { "$width": ITEMS_WIDTH.sixthElem, "$isCollage": isCollage },
                    React.createElement(TableBodyItemRemove, null,
                        React.createElement(CommonText, { text: getCostText({
                                // quantity: furniture.quantity,
                                quantity: 1,
                                price: furniture.price,
                            }) }),
                        React.createElement(CommonButton, { onClick: handleRemoveEstimaty(furniture) },
                            React.createElement(RemoveSvg, { fillColor: getFillColor(index) }))))));
        })),
        React.createElement(TableFooter, null,
            React.createElement(CommonText, { fontWeight: "700", fontSize: "14px", text: "\u0418\u0442\u043E\u0433\u043E" }),
            React.createElement(CommonText, { fontWeight: "700", fontSize: "14px", text: getTotalCostText }))));
});
