import { getProjectInfo } from '../get-project-info';
export var getHeaders = function () {
    var _a = getProjectInfo(), projectName = _a.projectName, projectId = _a.projectId;
    return {
        headers: {
        // Authorization: getAuthBearer(),e
        // 'X-Project-ID': projectId,
        // 'X-Project-Name': projectName,
        },
        timeout: 480000
    };
};
