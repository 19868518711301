var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useState } from "react";
import { CheckboxWrapper, CheckboxLabel, CheckboxInput } from "./styled";
export var Checkbox = memo(function Checkbox(_a) {
    var children = _a.children, onChange = _a.onChange;
    var _b = __read(useState(false), 2), isChecked = _b[0], setIsChecked = _b[1];
    var handleCheckboxChange = function (event) {
        setIsChecked(event.target.checked);
        onChange(event.target.checked);
    };
    return (React.createElement(CheckboxWrapper, null,
        React.createElement(CheckboxInput, { checked: isChecked, onChange: handleCheckboxChange }),
        React.createElement(CheckboxLabel, null, children)));
});
