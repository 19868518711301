import { fetchGetRequests } from "src/api/fetch/get";
//import { fetchPostRequests } from 'src/api/fetch/post';
//import { collageUrl } from "src/api/urls/get-collage";
import { responseDataFormatter } from "./response-formatter";
// import { MOCK_FURNITURES, MOCK_NEW_CATEGOR } from "../mock";s
import { collageUrl } from "../../urls/get-collage";
export var getCollageRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    fetchGetRequests({
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        responseDataFormatter: responseDataFormatter,
        url: collageUrl,
        //  urlId: `?collage_json_id=${collage_json_id}`,
    });
};
