import { fetchGetRequests } from "src/api/fetch/get";
import { usersUrl } from "src/api/urls/get-users";
import { responseDataFormatter } from "./response-formatter";
// FOR_ADMIN
export var getUsersRequest = function (_a) {
    var onSuccess = _a.onSuccess, onError = _a.onError, _b = _a.startLoadingCallback, startLoadingCallback = _b === void 0 ? function () { } : _b, _c = _a.stopLoadingCallback, stopLoadingCallback = _c === void 0 ? function () { } : _c;
    fetchGetRequests({
        url: usersUrl,
        startLoadingCallback: startLoadingCallback,
        stopLoadingCallback: stopLoadingCallback,
        onSuccess: onSuccess,
        onError: onError,
        responseDataFormatter: responseDataFormatter,
        /** ONLY_FOR_DEVELOP */
        // mockData: MOCK_ROOMS,
    });
};
