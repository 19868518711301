var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  height: 60px;\n  align-items: center;\n  background-color: #fff;\n  padding: 0 20px;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  @media (max-width: 768px) {\n    display: none;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  height: 60px;\n  align-items: center;\n  background-color: #fff;\n  padding: 0 20px;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  @media (max-width: 768px) {\n    display: none;\n  }\n"])));
export var MenuLeft = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  gap: 20px;\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.6);\n    border-radius: 7px;\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  gap: 20px;\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.6);\n    border-radius: 7px;\n  }\n"])));
export var MenuCenter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n"])));
export var StyledImg = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 20px;\n  width: auto;\n  transition: background-color 0.2s;\n  cursor: pointer;\n  &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  }\n"], ["\n  height: 20px;\n  width: auto;\n  transition: background-color 0.2s;\n  cursor: pointer;\n  &:hover {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n  }\n"])));
export var Title = styled.h1(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #000;\n  text-align: center;\n  font-family: \"Nunito Sans\", sans-serif;\n  font-size: 22px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n"], ["\n  color: #000;\n  text-align: center;\n  font-family: \"Nunito Sans\", sans-serif;\n  font-size: 22px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n"])));
export var TitleText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
export var MenuRight = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  z-index: 1000;\n  position: relative;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  z-index: 1000;\n  position: relative;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
// const LanguageSwitcherButton = styled.button`
//   padding: 5px 10px;
//   background-color: #f0f0f0;
//   border: none;
//   cursor: pointer;
//   border-radius: 5px;
//   &:hover {
//     background-color: #e0e0e0;
//   }
// `;
